import { configureStore } from '@reduxjs/toolkit';
import appContextReducer from './header/reducer';
import nodeMenuReducer from './data-animator/right-side-menu';
import sidebarReducer from './sidebar/reducer';
import toastReducer from './toastSlice';
import chatReducer from './chat/chatSlice';
import filterReducer from './sidebar/filterSlice';
const store = configureStore({
  reducer: {
    headerContext: appContextReducer,
    nodeMenuContext: nodeMenuReducer,
    sidebarContext: sidebarReducer,
    toastContext: toastReducer,
    chat: chatReducer,
    filter: filterReducer,
  },
});

const restoreDataFromLocal = () => {
  const userInfo = localStorage.getItem('user_info');
  // const accessToken = localStorage.getItem('access_token');
  if (userInfo) {
    // const parsed: any = JSON.parse(userInfo);
    // store.dispatch(setUserContext({
    // 	isAuthenticated: accessToken ? true : false,
    // 	userInfo: parsed
    // }));
  }
};

restoreDataFromLocal();
export type RootState = ReturnType<typeof store.getState>;
export default store;
