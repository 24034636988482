import { Grid } from "@mui/material";
import TabPanel from "../../atoms/Tabpanel/index";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const RightTabs = ({ tabs, selectedTab, handleType, styles }: any) => {
  const location = useLocation()
  const [selTab, setSelTab] = useState('')

  useEffect(() => {
    const pathname = location?.pathname?.startsWith('/') ? location.pathname.slice(1) : location.pathname;
    const currentTab = tabs?.filter((item: any) =>
      item?.value === pathname || item?.value === location?.pathname
    );
    if (currentTab?.length > 0) {
      setSelTab(currentTab[0]?.name);
    } else {
      setSelTab('');
    }
  }, [location, tabs]);

  return (
    <div className="flex">
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",

        }}
      >
        <Grid
          item
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <TabPanel
            data-testid="view-process-tabs"
            {...{
              tabs: tabs,
              selectedTab: selTab,
            }}
            variant="standard"
            textColor="primary"
            handleTab={(item: any) => handleType(item)}
            sx={{
              "& .MuiTabs-root, .MuiTabs-scroller": {
                overflow: "visible !important",
              },
              "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                alignSelf: "stretch",
                flexDirection: "row",
                margin: 0,
                padding: 0,
              },
              "& .MuiToggleButtonGroup-root": {
                padding: "0px",
                margin: "0px",
              },
              "& .MuiBox-root": {
                padding: "0px",
                margin: "0px",
              },
              "& .MuiToggleButton-root": {
                fontWeight: 500,
                textTransform: "none",
                fontSize: "16px",
                borderRadius: "0px",
                border: "none",
                fontFamily: "Poppins",
                color: "rgb(94, 92, 113)",
                padding: "24px 4px 20px 4px",
                lineHeight: "24px",
                textAlign: "left",
              },
              "& .Mui-selected.MuiToggleButton-root": {
                padding: "24px 4px 18px 4px",
                backgroundColor: "#FFF",
                border: "none",
                boxShadow: "none",
                borderRadius: "none",
                color: "rgb(19, 15, 53)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                borderBottom: "2px solid rgb(95, 70, 255)",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              },
              ...styles,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RightTabs;
