import React, { forwardRef } from 'react';
import { Button as MUIButton } from '@mui/material';
import styles from './button.module.scss';

// eslint-disable-next-line react/display-name
export const CustomButton = forwardRef((props: any, ref: any) => {
  return (
    <>
      {
        <MUIButton
          ref={ref}
          className={styles.button}
          variant='contained'
          size={props.size}
          onClick={props.onClick}
          disabled={props.disabled}
          type={props.type}
          sx={{
            opacity:props.disabled ? 0.5 :1,
            background:props.disabled ? "lightgrey !important" :"rgb(95, 70, 254)",
            width:'100%'
          }}
          {...props}
        >
          {props.children}
        </MUIButton>
      }
    </>
  );
});
