
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as BottomLogo } from '../../../assets/svg/centerLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import styles from './login.module.scss';
import { Button, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SetupWrapper from './getStartedWrapper';
import { CheckBox } from '../../../components/atoms/CheckboxWithName';
const defaultTheme = createTheme();

export default function CompanySetup({ children, question }: any) {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

    };
    const navigate = useNavigate();

    const [step, setStep] = React.useState(1)

    const hanldeStepNext = (step: any) => {
        if (step == 3) {
            navigate('/')
        } else {
            setStep(step + 1)
        }
    }

    const handleStepBack = (step: any) => {
        setStep(step - 1)

    }

    const sizeData = ['1 - 5', '5 - 25', '25 - 200', '200 - 1000', '1000+'];
    const moduleOptions = [
        "Associate",
        "Admin",
        "SuperAdmin",
        "Manager",
    ];
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component='main'
                sx={{ height: '100vh' }}
                className={`font-poppins ${styles.mainCont}`}
            >

                <CssBaseline />

                <Grid
                    item
                    md={12}
                    // className={styles.bgimage}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#5B47F5',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        <Box
                            component='form'
                            noValidate
                            onSubmit={handleSubmit}
                            display={'flex'}
                            gap={'24px'}
                            flexDirection={'column'}

                            className={styles.mainLoginCont}
                            sx={{
                                background: 'white',
                                width: '550px',
                                height: step == 1 ? '490px' : (step == 2 ? '600px' : '490px'),
                                padding: '0px 30px'
                            }}
                        >
                            {step === 1 && <SetupWrapper question="Tell us your company name">
                                <Grid sx={{ width: '100%' }}>
                                    <Grid sx={{
                                        width: '100%'
                                    }}>
                                        <FormInput
                                            variant='filled'
                                            size='small'
                                            label='Company Name'
                                            sx={{}}
                                        />
                                        <Grid item display={"flex"} justifyContent={'start'} mt={1}>
                                            <Typography
                                                color={'rgb(95, 70, 254)'}
                                                className='fontStyles'

                                                sx={{ marginLeft: '0px', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline' }}
                                            >
                                                I am a freelancer
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SetupWrapper>}

                            {step === 2 && <SetupWrapper question="Tell us your company size">
                                <Grid sx={{ width: '100%' }}>
                                    <Grid sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px'
                                    }}>
                                        {sizeData.map((item) => {
                                            return <Grid sx={{
                                                display: 'flex', gap: '10px', alignItems: 'center'
                                            }} className={styles.sizeStyles}>
                                                <Checkbox />
                                                <p>{item}</p>
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            </SetupWrapper>}

                            {step === 3 && <SetupWrapper question="What is your role at the company?">
                                <Grid sx={{ width: '100%' }}>
                                    <Grid sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px'
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="module-select-label">Role</InputLabel>
                                            <Select
                                                labelId="module-select-label"
                                                id="module-select"
                                                value={"Associate"}
                                                onChange={() => { }}
                                                input={<OutlinedInput label="Role" />}
                                            >
                                                {moduleOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormInput
                                            variant='filled'
                                            size='small'
                                            label='Other'
                                            sx={{}}
                                        />
                                    </Grid>
                                </Grid>
                            </SetupWrapper>}

                            {step === 1 && <Grid sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                gap: '24px'
                            }}>
                                <Button sx={{
                                    background: 'white',
                                    borderRadius: '4px',
                                    marginTop: '50px',
                                    color: 'black !important',
                                    border: '1px solid #B0B0B0'
                                }} onClick={() => hanldeStepNext(step)}>Skip</Button>
                                <Button sx={{
                                    background: 'rgb(48, 79, 254) !important',
                                    borderRadius: '4px',
                                    marginTop: '50px',
                                    color: 'white !important'
                                }} onClick={() => hanldeStepNext(step)}>Continue</Button>
                            </Grid>}
                            {step !== 1 && <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '450px',
                                gap: '24px',
                            }}>
                                <Grid>
                                    <Button sx={{
                                        background: 'white',
                                        borderRadius: '4px',
                                        marginTop: '50px',
                                        color: 'black !important',
                                        border: '1px solid #B0B0B0'
                                    }} onClick={() => handleStepBack(step)}>Back</Button>
                                </Grid>
                                <Grid>
                                    <Button sx={{
                                        background: 'white',
                                        borderRadius: '4px',
                                        marginTop: '50px',
                                        color: 'black !important',
                                        border: '1px solid #B0B0B0'
                                    }} onClick={() => hanldeStepNext(step)}>Skip</Button>
                                    <Button sx={{
                                        background: 'rgb(48, 79, 254) !important',
                                        borderRadius: '4px',
                                        marginTop: '50px',
                                        color: 'white !important',
                                        marginLeft: '10px'
                                    }} onClick={() => hanldeStepNext(step)}>Continue</Button>
                                </Grid>
                            </Grid>}
                        </Box>
                    </Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '40%',
                        zIndex: 1
                    }}>
                        <BottomLogo />
                    </Grid>
                </Grid>

            </Grid>
        </ThemeProvider>
    );
}
