import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import store from "./store";
import ToastContainer from "./components/molecules/toast/toastContainer";
import { StyledEngineProvider } from "@mui/material";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById(
  "root"
) as HTMLElement);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </QueryClientProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
reportWebVitals();
