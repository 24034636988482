import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import styles from './styles.module.scss';
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const SalesChart = ({ label, data }: any) => {
  //   console.log('daat in chart', label, data );
  const getData = () => {
    const labels = Array(data.length).fill(''); // Remove labels by setting empty strings

    const datasets = [
      {
        label: label,
        data: data,
        borderColor: '#90EF9E', // Light green for the line
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150); // Adjust gradient height to 150px
          gradient.addColorStop(0, '#8DF4A4'); // Bright green at the top
          gradient.addColorStop(1, 'rgba(141, 244, 164, 0)'); // Fades to transparent at the bottom
          return gradient;
        },
        fill: true,
        borderWidth: 2.5,
        pointRadius: 0,
        tension: 0.4, // Slight tension for smooth curve
      },
    ];
    return {
      labels: labels,
      datasets: datasets,
    };
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false, // Hide y-axis
      },
      x: {
        display: false, // Hide x-axis
      },
    },
    interaction: {
      mode: 'nearest',
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      datalabels: {
        display: false, // Hide data labels on the bars
      },
    },
    elements: {
      line: {
        borderColor: '#90EF9E', // Green line color
      },
    },
  };

  return (
    <div className={styles.chartStyles}>
      {' '}
      {/* Set the container to 100% width */}
      <Chart
        type="line"
        data={getData()}
        options={options}
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};
