import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const initialState: any = {
  mainHeader: 'Dashboard',
  subHeader: '',
  isDashboard: true,
  permissions: {},
  loading: false,
  userName: '',
  showSide: false,
  tab: 0,
  dashboardDaysFilter: '7d',
};

export const appContextSlice = createSlice({
  name: 'tabpanel',
  initialState,
  reducers: {
    switchDashboard: (state, action) => {
      state.isDashboard = action.payload?.isEnabled;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setUserNameApi: (state, action) => {
      state.userName = action.payload;
    },
    onMainTabChange: (state, action) => {
      state.mainHeader = action.payload?.name;
    },
    onsubTabChange: (state, action) => {
      state.subHeader = action.payload?.name;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setLoadingP: (state, action) => {
      state.loading = action.payload;
    },
    setShowSidebar: (state, action) => {
      state.showSide = action.payload;
    },
    setDashboardDaysFilter: (state, action) => {
      state.dashboardDaysFilter = action.payload; // Update the filter
    },
  },
});

export const {
  onMainTabChange,
  onsubTabChange,
  switchDashboard,
  setPermissions,
  setLoadingP,
  setUserNameApi,
  setShowSidebar,
  setTab,
  setDashboardDaysFilter,
} = appContextSlice.actions;
export default appContextSlice.reducer;
