import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as BottomLogo } from '../../../../assets/svg/centerLogo.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from '../../Login/login.module.scss';
import { CircularProgress, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiRequest } from '../../../../services/onboard';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const defaultTheme = createTheme();

export default function AdsAccountActivation() {
  const [loading, setLoading] = React.useState(true);
  const [activated, setActivated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const oauth_code = searchParams.get('code');
    const region = localStorage.getItem('amazon_ads_region');
    const token = localStorage.getItem('access_token');

    if (oauth_code) {
      const verifyOAuth = async () => {
        try {
          if (!token) {
            throw new Error('No access token found');
          }
          await apiRequest({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/amazon_ads/api/amazon_ads/callback?code=${oauth_code}&region=${region}`,
            // url: `http://localhost:8000/amazon_ads/api/amazon_ads/callback?code=${oauth_code}&region=${region}`,
            config: {
              headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
            token,
            dispatch,
          });
          // Handle success
          setActivated(true);
          localStorage.setItem('is_ads_onboarded', 'true');
          toast.success('Ads Region Activation Successfully!');
          setTimeout(() => navigate('/connections'), 2000);
        } catch (error: any) {
          let errorMsg = 'An unexpected error occurred';

          // Check if it's an Axios error with a response
          if (axios.isAxiosError(error) && error.response) {
            console.error(error.response);
            const details = error.response.data?.detail;

            // If 'details' is an array, map through it to get messages
            if (Array.isArray(details)) {
              errorMsg = details.map((detail: any) => detail.msg).join(', ');
            } else {
              errorMsg = error.message;
            }
          } else if (error instanceof Error) {
            errorMsg = error.message;
          }

          setErrorMessage(errorMsg);
          toast.error(errorMsg);
        } finally {
          setLoading(false);
        }
      };
      verifyOAuth();
    }
  }, [searchParams, navigate, dispatch]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: '100vh' }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />
        <Grid
          item
          md={12}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#5B47F5',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="16px"
              className={styles.mainLoginCont}
              sx={{
                background: 'white',
                width: '540px',
                height: '300px',
                padding: '0px 50px',
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: '#000', fontFamily: 'Poppins' }}
              >
                Account Activation
              </Typography>
              {loading ? (
                <CircularProgress sx={{ color: '#5B47F5' }} />
              ) : activated ? (
                <CheckCircleOutline sx={{ color: 'green', fontSize: '48px' }} />
              ) : errorMessage ? (
                <Typography variant="body1" sx={{ color: 'red' }}>
                  {errorMessage}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ color: 'red' }}>
                  Activation failed. Please try again.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid
            sx={{
              position: 'fixed',
              bottom: 0,
              left: '40%',
              zIndex: 1,
            }}
          >
            <BottomLogo />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
