// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Select,
  Mixins,
  Palette,
  Shadows,
  Theme,
  Transitions,
  ZIndex,
} from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';

// ----------------------------------------------------------------------

interface IStyledProps {
  theme: {
    mixins: Mixins;
    palette: Palette;
    shadows: Shadows;
    transitions: Transitions;
    typography: Typography;
    zIndex: ZIndex;
    unstable_strictMode?: boolean;
  };
  ownerState: {
    size: 'small' | 'medium';
  };
}

export const StyledSelect = styled(Select)(
  ({ theme, ownerState }: IStyledProps) => {
    const filledComponent = {
      background: 'inherit',
      outline: 0,
      '&:hover': {
        background: 'inherit',
        border: 'none',
      },
      '& .MuiSelect-filled': {
        border: '1px solid #5F46FF',
        backgroundColor: 'inherit',
        padding: '0px',
        margin: 0,
        color: '#5F46FF',
      },
      '&.Mui-focused .MuiSelect-filled': {
        border: '0px solid #5F46FF',
        backgroundColor: 'inherit',
        color: '#5F46FF',
      },
      '& .MuiSelect-filled:hover': {
        border: '1px solid #5F46FF',
        backgroundColor: 'inherit',
      },
      '&.MuiInputBase-formControl .MuiSelect-filled': {
        border: '0px solid #5F46FF',
        borderColor: '#5F46FF',
        color: '#5F46FF',
        backgroundColor: 'inherit',
      },
      '&.Mui-focused': {
        background: 'inherit',
      },

    };
    const outlineComponent = {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
      '& .MuiSelect-outlined': {
        // padding:'14px',
        border: '0px solid #ced4da',
        color: 'black',
        ...(ownerState.size == 'small' && {
          marginBottom: '6px',
          marginTop: '2px',
        }),
      },
    };
    return {
      position: 'relative',
      ...outlineComponent,
      ...filledComponent,
    };
  }
);
