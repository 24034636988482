import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as BottomLogo } from "../../../assets/svg/centerLogo.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../Login/login.module.scss";
import { CircularProgress, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { addToast } from "../../../store/toastSlice";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../../services/onboard";

const defaultTheme = createTheme();

export default function EmailVerify() {
  const [loading, setLoading] = React.useState(true);
  const [verified, setVerified] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token: any = queryParams.get("token");
    const exist = localStorage.getItem(token)
    if (token) {
      apiRequest({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL
          }/auth/verify_email?token=${token}`,
        dispatch,
        setLoading,
        successMessage: "Email verified successfully! Redirecting to login...",
        errorMessage: "Verification failed. Please try again.",
        successRedirect: "/login",
        navigate,
      })
        .then(() => {
          setVerified(true);
        })
        .catch((error) => {
          console.error("Verification failed:", error);
        });
    } else {
      setLoading(false);
      console.error("Token Does not exist");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />

        <Grid
          item
          md={12}
          sm={12}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "#5B47F5",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              padding: { xs: "16px", sm: "32px" },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="16px"
              className={styles.mainLoginCont}
              sx={{
                background: "white",
                width: { xs: "100%", sm: "400px", md: "540px" },
                height: "300px",
                padding: { xs: "16px 24px", sm: "0px 50px" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "1.5rem", md: "1.75rem" },
                }}
              >
                Email Verification
              </Typography>
              {loading ? (
                <CircularProgress sx={{ color: "#5B47F5" }} />
              ) : verified ? (
                <CheckCircleOutline sx={{ color: "green", fontSize: "48px" }} />
              ) : (
                <Typography variant="body1" sx={{ color: "red" }}>
                  Verification failed. Please try again.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid
            sx={{
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
              padding: { xs: "0 16px", sm: "0 32px" },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <BottomLogo style={{ maxWidth: "100%", height: "auto" }} />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
