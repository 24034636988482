import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChatState {
    chats: any[];
    archivedChats: any[];
    selectedChatId: string | null;
    messages: any[];
    pendingMessages: Record<string, string>; 
    loading: Record<string, boolean>; // Stores loading state per chatId
    loadingMessage: Record<string, boolean>; // Stores loadingMessage state per chatId
    users: any[];
}

const initialState: ChatState = {
    chats: [],
    archivedChats: [],
    selectedChatId: null,
    messages: [], 
    pendingMessages: {},  
    loading: {},
    loadingMessage: {},
    users: [],
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // Chat List Management
        setChats: (state, action: PayloadAction<any[]>) => {
            state.chats = action.payload;
        },
        setArchivedChats: (state, action: PayloadAction<any[]>) => {
            state.archivedChats = action.payload;
        },

        // Selected Chat Management
        setSelectedChatId: (state, action: PayloadAction<string>) => {
            state.selectedChatId = action.payload;
        },

        // Message Management
        setMessages: (state, action: PayloadAction<any[]>) => {
            state.messages = action.payload;
        },
        addMessage: (state, action: PayloadAction<any>) => {
            state.messages.push(action.payload);
        },

        // Users Management
        setUsers: (state, action: PayloadAction<any[]>) => {
            state.users = action.payload;
        },

        // Pending Messages Management
        setPendingMessage: (state, action: PayloadAction<{ chatId: string; message: string }>) => {
            const { chatId, message } = action.payload;
            state.pendingMessages[chatId] = message; // Set the pending message for this chatId
        },
        clearPendingMessage: (state, action: PayloadAction<string>) => {
            const chatId = action.payload;
            delete state.pendingMessages[chatId]; // Remove the pending message for the specified chatId
        },

        // Loading States Management
        setLoading: (state, action: PayloadAction<{ chatId: string; loading: boolean }>) => {
            const { chatId, loading } = action.payload;
            state.loading[chatId] = loading;
        },
        setLoadingMessage: (state, action: PayloadAction<{ chatId: string; loadingMessage: boolean }>) => {
            const { chatId, loadingMessage } = action.payload;
            state.loadingMessage[chatId] = loadingMessage;
        },
    },
});

export const {
    setChats,
    setArchivedChats,
    setSelectedChatId,
    setMessages,
    addMessage,
    setLoading,
    setLoadingMessage,
    setPendingMessage,
    clearPendingMessage,
    setUsers,
} = chatSlice.actions;

export default chatSlice.reducer;
