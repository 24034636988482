import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as BottomLogo } from '../../../assets/svg/centerLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import styles from './login.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const defaultTheme = createTheme();

export default function SetupWrapper({ children, question }: any) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        gap:'20px'
      }}>

          <p style={{ fontSize: '16px', color: '5D616F' }}>Please fill in details here</p>
          <p className={styles.mainLoginText}>{question}</p>

          {children}
      </Grid>
    </ThemeProvider>
  );
}
