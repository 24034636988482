import React, { useState } from "react";
import { SearchInput } from "../../../components/atoms/search/index";
import Select from "../../../components/atoms/Select";
import { ReactComponent as DownArrowIcon } from "../../../assets/svg/downarr.svg";

const SearchHeader = ({ onViewChange} : { onViewChange: any }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{ width: "265px", paddingTop: "16px", paddingBottom: "8px" }}
        className="flex "
      >
        <SearchInput />
        <Select
          value={"forcasted_trend"}
          options={[
            { label: "Forcasted Trend", value: "forcasted_trend" },
            { label: "Impact products", value: "impact_products" },
          ]}
          variant="outlined"
          size="small"
          multiple={false}
          optionsType="default"
          name="timeline"
          onChange={onViewChange}
          IconComponent={() => <DownArrowIcon fill="#fff" />}
          sx={{
            color: "#fff !important",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            borderRadius: "8px",
            paddingTop: "3px",
            width: "223px",
            height: "37px",
            border: "none",
            background: "#5F46FF !important",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              color: "#fff !important",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-outlined": {
              color: "#fff !important",
            },
            marginLeft: 2,
          }}
          Menustyle={{
            borderRadius: "4px",
            boxShadow:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
            background: "#FFF",
            "& .MuiMenuItem-root": {
              color: "#5F46FF",
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              minwidth: "100%",
              borderRadius: "8px",
              paddingTop: "3px",
              borderBottom: "1px solid rgb(73, 69, 79)",
            },
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          paddingTop: "8px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Select
            value={"15D"}
            options={[
              { label: "7 days", value: "7D" },
              { label: "15 days", value: "15D" },
              { label: "1 months", value: "1M" },
              { label: "3 months", value: "3M" },
              { label: "6 months", value: "6M" },
              { label: "1 years", value: "1Y" },
              { label: "3 years", value: "3Y" },
              { label: "5 years", value: "5Y" },
            ]}
            variant="outlined"
            size="small"
            multiple={false}
            optionsType="default"
            name="timeline"
            onChange={(e: any) => console.log(e)}
            IconComponent={() => <DownArrowIcon fill="#fff" />}
            sx={{
              color: "#fff !important",
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              borderRadius: "8px",
              paddingTop: "3px",
              width: "223px",
              height: "37px",
              border: "none",
              background: "#5F46FF !important",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                color: "#fff !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-outlined": {
                color: "#fff !important",
              },
            }}
            Menustyle={{
              borderRadius: "4px",
              boxShadow:
                "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
              background: "#FFF",
              "& .MuiMenuItem-root": {
                color: "#5F46FF",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                minwidth: "100%",
                borderRadius: "8px",
                paddingTop: "3px",
                borderBottom: "1px solid rgb(73, 69, 79)",
              },
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
    </div>
  );
};

export default SearchHeader;
