/* eslint-disable @typescript-eslint/no-explicit-any */
// Toast.js
'use client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { removeToast } from '../../../store/toastSlice';
import tick from '../../../assets/images/tick.svg'
import warning from '../../../assets/images/warning.svg'
const Toast = ({ id, type, message }: any) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(removeToast(id));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, [id, dispatch]);

  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '16px',
        gap: '16px',
        background: type == 'success' ? '#3ABC4F' : '#EC524A',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div>
        <img
          src={type == 'success' ? tick: warning}
          alt="tick"
          width={24}
          color='white'
          height={24}
        />
      </div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: 'var(--font-sans)',
          lineHeight: '18.77px',
          color: 'white',
          width: '220px',
        }}
      >
        {message}
      </div>
    </div>
  );
};

export default Toast;
