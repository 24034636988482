import { useDispatch, useSelector } from 'react-redux';
import arrowDropdown from '../../../assets/svg/sidebar/arrow.svg';
import styles from './sidebarLayout.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import dashboard from '../../../assets/svg/sidebar/dashboard.svg';
import help from '../../../assets/svg/sidebar/help.svg';
import locationSVG from '../../../assets/svg/sidebar/location.svg';
import fullfillment from '../../../assets/svg/sidebar/fullfillment.svg';
import promotions from '../../../assets/svg/sidebar/promotions.svg';
import orderType from '../../../assets/svg/sidebar/orderType.svg';
import {
  useArchiveChatChatChatIdArchivePatch,
  useArchiveChatChatChatIdUnArchivePatch,
  useDeleteChatChatChatIdDeletePatch,
  useListChatsChatChatsGet,
} from '../../../api/apiComponents';
import {
  setArchivedChats,
  setChats,
  setLoadingMessage,
  setSelectedChatId,
} from '../../../store/chat/chatSlice';
import analyst from '../../../assets/svg/analyst.svg';
import connectionsLogo from '../../../assets/svg/connectionsLogo.svg';
import newChat from '../../../assets/svg/newChat.svg';
import more from '../../../assets/svg/more.svg';
import settings from '../../../assets/svg/settingsIcon.svg';
import home from '../../../assets/svg/sidebar/home.svg';
import { useMediaQuery } from '@mui/system';
import { setShowSidebar } from '../../../store/header/reducer';
import { Skeleton } from '@mui/material';
import FilterDropdownComponent from './FilterDropdownComponent';

const SidebarNew = () => {
  const location = useLocation();
  let { showSide } = useSelector((state: any) => state?.headerContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: archiveChat } = useArchiveChatChatChatIdArchivePatch();
  const { mutate: unArchive } = useArchiveChatChatChatIdUnArchivePatch();
  // delete chat
  const { mutate: deleteChat } = useDeleteChatChatChatIdDeletePatch();
  const getSidebarData = () => {
    if (location.pathname.startsWith('/xylic-ai')) {
      return chatbotSidebar;
    }
    if (location.pathname.startsWith('/new-insights')) {
      return dashboardData.slice(1, -1);
    }
    return connectionsSidebar;
  };
  const { chats = [] } = useSelector((state: any) => state.chat || {});

  const shouldFetchChats = useMemo(() => {
    return location.pathname.startsWith('/xylic-ai');
  }, [location.pathname]);

  const {
    data: chatData = [],
    // refetch,
    isLoading: loadingChats,
  } = useListChatsChatChatsGet({}, { enabled: shouldFetchChats });

  const { activeChats, archivedChats } = useMemo(() => {
    if (chatData.length > 0) {
      const active = chatData.filter(
        (chat: any) => !chat.is_archived && !chat.is_deleted
      );
      const archived = chatData.filter((chat: any) => chat.is_archived);
      return { activeChats: active, archivedChats: archived };
    }
    return { activeChats: [], archivedChats: [] };
  }, [chatData]);

  useEffect(() => {
    if (activeChats.length > 0 || archivedChats.length > 0) {
      dispatch(setChats(activeChats));
      dispatch(setArchivedChats(archivedChats));
    }
  }, [activeChats, archivedChats, dispatch]);

  // useEffect(() => {
  //   if (chatData.length > 0) {
  //     const activeChats = chatData.filter((chat: any) => !chat.is_archived);
  //     const archivedChats = chatData.filter((chat: any) => chat.is_archived);
  //     dispatch(setChats(activeChats));
  //     dispatch(setArchivedChats(archivedChats));
  //   }
  // }, [chatData, dispatch]);

  const { chatIdPrev } = useParams();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const handleSelectChat = (chatId: string) => {
    dispatch(setSelectedChatId(chatId));
    if (chatIdPrev) {
      dispatch(
        setLoadingMessage({ chatId: chatIdPrev, loadingMessage: false })
      );
    }
    navigate(`/xylic-ai/${chatId}`);
    if (isSmallScreen) {
      dispatch(setShowSidebar(true));
    }
  };

  const organizeChatsByDate = () => {
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(today).setDate(new Date(today).getDate() - 1);
    const lastWeek = new Date(today).setDate(new Date(today).getDate() - 7);
    const sortByDateDesc = (a: any, b: any) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    const todayChats = chats
      .filter(
        (chat: any) => new Date(chat.updated_at).setHours(0, 0, 0, 0) === today
      )
      .sort(sortByDateDesc);
    const yesterdayChats = chats
      .filter(
        (chat: any) =>
          new Date(chat.updated_at).setHours(0, 0, 0, 0) === yesterday
      )
      .sort(sortByDateDesc);
    const last7DaysChats = chats
      .filter(
        (chat: any) =>
          new Date(chat.updated_at).setHours(0, 0, 0, 0) > lastWeek &&
          new Date(chat.updated_at).setHours(0, 0, 0, 0) < yesterday
      )
      .sort(sortByDateDesc);
    const olderChats = chats
      .filter(
        (chat: any) =>
          new Date(chat.updated_at).setHours(0, 0, 0, 0) <= lastWeek
      )
      .sort(sortByDateDesc);

    return { todayChats, yesterdayChats, last7DaysChats, olderChats };
  };

  const { todayChats, yesterdayChats, last7DaysChats, olderChats } =
    organizeChatsByDate();

  const userName = localStorage.getItem('name') || 'Unknown User';
  const ProfileIcon = () => (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: '#B3B3B3',
        color: '#fff',
        fontSize: '15px',
        fontFamily: 'Inter',
        fontWeight: 400,
        display: 'flex',
      }}
    >
      {userName[0]?.toUpperCase()}
    </div>
  );

  const CustomSidebarItem = ({ child, disabled }: any) => {
    const isActive = location.pathname.startsWith(child?.path);

    const [openDropDown, setOpenDropDown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const iconRef = useRef<HTMLImageElement | null>(null);

    const handleLogout = () => {
      console.log('Logout');
      localStorage.clear();
      navigate('/login');
    };
    const handleToggleDropdown = (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpenDropDown(!openDropDown);
    };
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          iconRef.current &&
          !iconRef.current.contains(event.target as Node)
        ) {
          setOpenDropDown(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const handleClick = () => {
      if (!disabled) {
        if (child?.onClick) {
          child.onClick();
        } else if (child?.path) {
          navigate(child.path);
        }
        if (isSmallScreen) {
          dispatch(setShowSidebar(true));
        }
      }
    };

    return (
      <div
        className={`${styles.childContainer} ${isActive ? styles.active : ''} ${disabled ? styles.disabled : ''}`}
        onClick={handleClick}
        onMouseEnter={(e) => {
          if (!disabled) {
            e.currentTarget.style.backgroundColor = '#F8FAFC';
          }
        }}
        onMouseLeave={(e) => {
          if (!disabled && !isActive) {
            e.currentTarget.style.backgroundColor = 'transparent';
          }
        }}
      >
        {child.icon &&
          (typeof child.icon === 'string' ? (
            <img src={child.icon} alt="" width={20} height={20} />
          ) : (
            child.icon
          ))}
        <div style={{ fontFamily: 'Inter', fontSize: '14px' }}>
          {child?.label}
        </div>

        {child.isProfile && (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={settings}
              alt="options"
              onClick={handleToggleDropdown}
              // ref={iconRef}
              style={{
                width: '16px',
                height: '16px',
                cursor: 'pointer',
              }}
            />
            {openDropDown && (
              <div
                ref={dropdownRef}
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  top: '70%',
                  right: -20,
                  marginTop: '4px',
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '4px 0',
                  zIndex: 1000,
                  width: '120px',
                }}
              >
                <button
                  onClick={handleLogout}
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: '4px 16px',
                    textAlign: 'left',
                    width: '100%',
                    cursor: 'pointer',
                    fontSize: '14px',
                    color: '#333',
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = '#f1f1f1')
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = 'transparent')
                  }
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const { permissions } = useSelector((state: any) => state?.headerContext);
  const dashboardData = [
    {
      label: '',
      children: [
        { label: 'Home', icon: home, path: '/home' },
        {
          label: 'Dashboard',
          icon: dashboard,
          path: '/new-insights',
          disabled: !permissions.insights, //!permissions.insights,
        },
        {
          label: 'AI Analyst',
          icon: analyst,
          path: '/xylic-ai',
          disabled: !permissions.insights,
        },
        { label: 'Connections', icon: connectionsLogo, path: '/connections' },
      ],
    },
    {
      label: 'Drill by',
      children: [
        // { label: 'Customer Segments', icon: customerSegment, path: '/segments' },
        {
          label: 'Location',
          icon: locationSVG, //path: '/location'
          isDashboardFilter: true,
        },
        {
          label: 'Fulfillment Type',
          icon: fullfillment,
          // path: '/fulfillment-type',
          isDashboardFilter: true,
        },
        {
          label: 'Promotions Type',
          icon: promotions,
          // path: '/promotions'
          isDashboardFilter: true,
        },
        {
          label: 'Order Type',
          icon: orderType,
          // path: '/order-type'
          isDashboardFilter: true,
        },
      ],
    },
    {
      label: 'Product Level',
      children: [
        {
          label: 'Super Category',
          icon: arrowDropdown,
          // path: '/super-category',
          isDashboardFilter: true,
        },
        {
          label: 'Category',
          icon: arrowDropdown,
          //  path: '/category'
          isDashboardFilter: true,
        },
        {
          label: 'Sub-Category',
          icon: arrowDropdown,
          //  path: '/sub-category'
          isDashboardFilter: true,
        },
        {
          label: 'Segment',
          icon: arrowDropdown,
          // path: '/segment'
          isDashboardFilter: true,
        },
        {
          label: 'Sub-Segment',
          icon: arrowDropdown,
          //  path: '/sub-segment'
          isDashboardFilter: true,
        },
        {
          label: 'Brand',
          icon: arrowDropdown,
          //  path: '/brand'
          isDashboardFilter: true,
        },
        {
          label: 'Item',
          icon: arrowDropdown,
          // path: '/item'
          isDashboardFilter: true,
        },
      ],
    },
    {
      label: '',
      children: [
        { label: '' },
        { label: 'Help', icon: help, path: '/help' },
        {
          label: userName,
          icon: <ProfileIcon />,
          // path: '/',
          isProfile: true,
        },
        { label: '' },
      ],
    },
  ];

  const connectionsSidebar = [
    {
      label: '',
      Children: [],
    },
  ];

  const chatbotSidebar = [
    // {
    //   label: '',
    //   children: [{ label: 'New Chat', icon: newChat, path: '/xylic-ai' }],
    // },
    {
      label: '',
      children: [
        {
          label: 'Today',
          icon: '',
          children: [
            { label: 'New Chat', icon: newChat, path: '/xylic-ai', flag: true },
            ...todayChats.map((chat: any) => ({
              label: chat.title,
              id: chat.id,
              onClick: () => handleSelectChat(chat.id),
            })),
          ],
          isChat: true,
          class: '',
        },
        {
          label: 'Yesterday',
          icon: '',
          children: yesterdayChats.map((chat: any) => ({
            label: chat.title,
            id: chat.id,
            onClick: () => handleSelectChat(chat.id),
          })),
          isChat: true,
        },
        {
          label: 'Last 7 days',
          icon: '',
          children: last7DaysChats.map((chat: any) => ({
            label: chat.title,
            id: chat.id,
            onClick: () => handleSelectChat(chat.id),
          })),
          isChat: true,
        },
        {
          label: 'Older',
          children: olderChats.map((chat: any) => ({
            label: chat.title,
            id: chat.id,
            onClick: () => handleSelectChat(chat.id),
          })),
          isChat: true,
        },
      ],
    },
    {
      label: 'Archived',
      children: [
        ...archivedChats.map((chat: any) => ({
          label: chat.title,
          id: chat.id,
          onClick: () => handleSelectChat(chat.id),
          isArchived: true,
        })),
      ],
      isChat: true,
    },
  ];

  const RecursiveSidebarItem = ({ item }: { item: any }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // console.log('item', item);
    // const isActive = location.pathname === item?.id;

    const [dropdownStates, setDropdownStates] = useState<{
      [key: string]: boolean;
    }>({});

    const handleToggleDropdown = (chatId: string) => {
      setDropdownStates((prev) => ({
        ...prev,
        [chatId]: !prev[chatId],
      }));
    };
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(`.${styles.dropdownMenu}`)) return;
      setDropdownStates({});
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleItemClick = (child: any) => {
      if (child?.onClick) {
        child.onClick();
      } else if (child?.path) {
        navigate(child.path);
      }
    };

    const handleDeleteChat = (chatId: string) => {
      console.log(`Delete chat with id: ${chatId}`);
      deleteChat({ pathParams: { chatId } });
      dispatch(setChats(chats.filter((chat: any) => chat.id !== chatId)));
    };

    const handleArchiveChat = (chatId: string) => {
      archiveChat({ pathParams: { chatId } });
      dispatch(setChats(chats.filter((chat: any) => chat.id !== chatId)));
      dispatch(
        setArchivedChats([
          ...archivedChats,
          chatData.find((chat: any) => chat.id === chatId),
        ])
      );
    };

    const handleUnArchiveChat = (chatId: string) => {
      unArchive({ pathParams: { chatId } });
      dispatch(
        setChats([
          ...chats,
          archivedChats.find((chat: any) => chat.id === chatId),
        ])
      );
      dispatch(
        setArchivedChats(
          archivedChats.filter((chat: any) => chat.id !== chatId)
        )
      );
    };
    if (location.pathname.startsWith('/xylic-ai') && loadingChats) {
      return (
        <div className="p-4">
          <Skeleton
            variant="rectangular"
            height={20}
            sx={{ borderRadius: 2 }}
          />
          <Skeleton
            variant="rectangular"
            height={10}
            width={50}
            sx={{ borderRadius: 2, mt: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={10}
            width={50}
            sx={{ borderRadius: 2, mt: 1 }}
          />
        </div>
      );
    }
    if (!item?.children || item?.children?.length === 0) {
      return null;
    }
    return (
      <div>
        {item.label && (
          <div className={styles.DashTextHeader}>{item.label}</div>
        )}

        {item.children &&
          item.children.map((child: any) => {
            const isActiveChat = location.pathname.includes(child.id);
            const isStartChatBtn = child.flag; // Check if it's "Start New Chat Button"
            return child.children ? (
              <RecursiveSidebarItem key={child.id} item={child} />
            ) : (
              <div
                key={child.id}
                className={`${
                  isActiveChat
                    ? `${styles.chatItem} ${styles.active}`
                    : styles.chatItem
                }`}
                onClick={() => handleItemClick(child)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = '#F8FAFC')
                }
                onMouseLeave={(e) =>
                  !isActiveChat &&
                  (e.currentTarget.style.backgroundColor = 'transparent')
                }
              >
                {child.icon &&
                  (typeof child.icon === 'string' ? (
                    <img src={child.icon} alt="" width={20} height={20} />
                  ) : (
                    child.icon
                  ))}
                <div
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px',
                    color: isStartChatBtn ? '#5F46FE' : 'inherit',
                  }}
                >
                  {child?.label}
                </div>
                {child.isDashboardFilter && (
                  <FilterDropdownComponent
                    key={child.label}
                    label={child.label}
                  />
                )}
                {item.isChat && !isStartChatBtn && (
                  <div
                    className={styles.chatIconContainer}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={more}
                      alt="options"
                      onClick={() => handleToggleDropdown(child.label)}
                      className={styles.smallIcon}
                    />

                    {dropdownStates[child.label] && (
                      <div
                        className={`${styles.dropdownMenu} ${styles.showDropdown}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {child.isArchived ? (
                          <button onClick={() => handleUnArchiveChat(child.id)}>
                            Unarchive
                          </button>
                        ) : (
                          <button onClick={() => handleArchiveChat(child.id)}>
                            Archive
                          </button>
                        )}
                        <button onClick={() => handleDeleteChat(child.id)}>
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className={styles.sidebar}>
      <ul className={styles.mainContainer}>
        <div
          className={styles.scrollContainer}
          //   style={{ display: showSide ? 'none' : '' }}
          style={{
            display: showSide ? 'none' : '',
          }}
        >
          <div className="flex flex-col h-full">
            <div>
              {dashboardData[0].children.map((child: any) => (
                <CustomSidebarItem
                  key={child.label}
                  child={child}
                  disabled={child?.disabled}
                />
              ))}
            </div>
            <div className="flex-1 overflow-scroll">
              {getSidebarData().map((item, index) => (
                <RecursiveSidebarItem key={index} item={item} />
              ))}
            </div>
            <div
              style={{
                borderTop: '1px solid #CECAF0',
                borderBottom: '1px solid #CECAF0',
              }}
            >
              {dashboardData[3].children.map((child: any) => (
                <CustomSidebarItem key={child.label} child={child} />
              ))}
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default SidebarNew;
