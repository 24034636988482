import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import xsiLogo from "../../assets/svg/xsiLogo.svg";
import AppBar from "@mui/material/AppBar";
import {
  Mixins,
  Palette,
  Shadows,
  Transitions,
  ZIndex,
} from "@mui/material";
import { Typography } from "@mui/material/styles/createTypography";

export const Logo = styled("div")(({ theme }: { theme: any }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#101828",
  fontFamily: "Inter,sans-serif",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "38px",
  // width: "250px",
  // height: "70px",
  // display: "flex",
  // alignItems: "center",
  // '& > .MuiPaper-root': {
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center center",
  //   backgroundSize: "90%",
  //   width: "200px",
  //   height: "45px",
  //   backgroundColor: "inherit"
  // },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const Search = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: '2px',
  display: "flex",
  alignItems: "center",
  marginLeft: 0,
  // width: "100%",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
}));

export const SearchIconWrapper = styled("div")(({ theme }: { theme: any }) => ({
  padding: '2px',
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right:5
}));

export const StyledInputBase = styled(InputBase)(
  ({ theme }: { theme: any }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: '5px',
      // vertical padding + font size from searchIcon
      paddingLeft:`10px`,
      transition: theme.transitions.create("width"),
      // width: "100%",
    },
  })
);

interface HeaderProps {
  theme: {
    mixins: Mixins;
    palette: Palette;
    shadows: Shadows;
    transitions: Transitions;
    typography: Typography;
    zIndex: ZIndex;
    unstable_strictMode?: boolean;
  };
}

export const StyledHeader = styled(AppBar)(({ theme }: HeaderProps) => {
  const headerStyles = {};
  return {
    ...headerStyles,
  };
});

export const ToolbarButtonContainer = styled("div")(() => {
  const toolbarButtonStyles = {
    display: "flex",
    alignItems: "center",
    // width: "100%",
  };
  return {
    ...toolbarButtonStyles,
  };
});
