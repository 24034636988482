import { TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { ReactComponent as XylicIcon } from '../../assets/svg/xylicIcon.svg';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDislikeMessageChatChatIdMessagesMessageIndexDislikePatch,
  useGetChatChatChatIdGet,
  useInitiateChatChatInitiateChatGet,
} from '../../api/apiComponents';
import {
  addMessage,
  clearPendingMessage,
  setLoading,
  setLoadingMessage,
  setMessages,
  setPendingMessage,
  setSelectedChatId,
  setUsers,
} from '../../store/chat/chatSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import Markdown from 'react-markdown';
import store, { RootState } from '../../store';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbDownOffAltRoundedIcon from '@mui/icons-material/ThumbDownOffAltRounded';
import { height, useMediaQuery, width } from '@mui/system';
import { head } from 'lodash';
interface Message {
  message: string;
  attachments: any[];
  dislike: boolean;
  timestamp: string;
  sender: string;
}

interface ChatData {
  messages: Message[];
  users: any[];
}

export const Chatbot = () => {
  const [search, setSearch] = useState('');
  const { chatId } = useParams();
  const websocketRef = useRef<WebSocket | null>(null);
  const token = localStorage.getItem('access_token');
  const wsBaseUrl = process.env.REACT_APP_WEBSOCKET_URL;
  const userName = localStorage.getItem('name') || 'Unknown User';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux states
  const users = useSelector((state: any) => state.chat.users || []);
  const mess = useSelector((state: any) => state.chat.messages || []);
  const loading = useSelector((state: any) =>
    chatId ? state.chat.loading[chatId] : false
  );
  const loadingMessage = useSelector((state: any) =>
    chatId ? state.chat.loadingMessage[chatId] : false
  );
  console.log(chatId, loadingMessage);

  // api calls
  const { refetch: createChat } = useInitiateChatChatInitiateChatGet(
    {},
    { enabled: false }
  );
  const { data: chatData, refetch } = useGetChatChatChatIdGet<ChatData>(
    {
      pathParams: {
        chatId: chatId || '',
      },
    },
    { enabled: !!chatId }
  );

  // Initialize loading state and data
  useEffect(() => {
    if (chatId) {
      dispatch(setLoading({ chatId: chatId, loading: true }));
      refetch().then((data) => {
        if (chatData?.messages && chatData?.messages.length > 0) {
          dispatch(setMessages(chatData?.messages));
        }
        if (chatData?.users && chatData?.users.length > 0) {
          dispatch(setUsers(chatData?.users));
        }
        dispatch(setLoading({ chatId: chatId, loading: false }));
      });
    } else {
      dispatch(setMessages([]));
    }
  }, [chatId, refetch, dispatch, chatData?.messages, chatData?.users]);

  // suggestions
  const suggestions = useMemo(
    () => [
      'Summarize my shop performance for last week',
      'Show me my top performing ASINs',
      'How do I increase my sales',
    ],
    []
  );
  const [tempLoading, setTempLoading] = useState(true);
  // WebSocket connection to handle sending messages
  useEffect(() => {
    if (!chatId || !token) return;
    if (websocketRef.current) {
      setTempLoading(false);
      websocketRef.current.close();
    }
    const connectWebSocket = () => {
      const wsUrl = `${wsBaseUrl}/${chatId}?access_token=${token}`;
      websocketRef.current = new WebSocket(wsUrl);

      websocketRef.current.onopen = () => {
        const pendingMessage = store.getState().chat.pendingMessages[chatId];
        if (pendingMessage) {
          dispatch(setLoadingMessage({ chatId: chatId, loadingMessage: true }));
          setTempLoading(true);
          const newMessage = {
            action: 'start_chat',
            message: pendingMessage,
            attachments: [],
          };
          websocketRef.current?.send(JSON.stringify(newMessage));

          dispatch(addMessage(newMessage));
          dispatch(clearPendingMessage(chatId));
        }
      };

      websocketRef.current.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        dispatch(addMessage(newMessage));
        // console.log(chatId, newMessage);
        dispatch(setLoadingMessage({ chatId: chatId, loadingMessage: false }));
        setTempLoading(false);
        // console.log(chatId, loadingMessage);
      };

      // let reconnectAttempts = 0;
      // const maxReconnectAttempts = 5;
      websocketRef.current.onclose = () => {
        console.error('WebSocket closed.');
        // if (reconnectAttempts < maxReconnectAttempts) {
        //   setTimeout(() => {
        //     reconnectAttempts++;
        //     connectWebSocket();
        //   }, 5000); // Retry every 5 seconds
        // } else {
        //   console.error("Max reconnect attempts reached.");
        // }
      };

      websocketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    };
    connectWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [chatId, token, dispatch, wsBaseUrl]);

  // Send message
  const handleSend = async (message: string) => {
    if (!message.trim()) return;
    setSearch('');

    if (!chatId) {
      // Creating a New Chat
      const response = await createChat();
      const newChatId = (response?.data as unknown as { chat_id: string })
        ?.chat_id;
      if (newChatId) {
        dispatch(setSelectedChatId(newChatId));
        dispatch(setPendingMessage({ chatId: newChatId, message }));
        dispatch(
          setLoadingMessage({ chatId: newChatId, loadingMessage: true })
        );
        setTempLoading(true);
        navigate(`/xylic-ai/${newChatId}`);
      }
    } else {
      if (
        websocketRef.current &&
        websocketRef.current.readyState !== WebSocket.OPEN
      ) {
        dispatch(setPendingMessage({ chatId, message }));
      } else {
        const newMessage = { action: 'start_chat', message, attachments: [] };
        websocketRef.current?.send(JSON.stringify(newMessage));
        dispatch(addMessage(newMessage));
        dispatch(setLoadingMessage({ chatId, loadingMessage: true }));
        setTempLoading(true);
      }
    }
  };

  // Scroll to the bottom when messages update
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mess]);

  const { mutate: dislikeMessage } =
    useDislikeMessageChatChatIdMessagesMessageIndexDislikePatch();
  const handleDislike = (index: number) => () => {
    dislikeMessage({
      pathParams: { chatId: chatId || '', messageIndex: index },
    });
    const updatedMessages = mess.map((message: any, i: any) =>
      i === index ? { ...message, dislike: true } : message
    );

    dispatch(setMessages(updatedMessages));
    console.log('dislike message', index);
  };
  const { showSide } = useSelector((state: RootState) => state.headerContext);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  // Render loading states
  if (loading) {
    return (
      <div
        style={{
          boxShadow: '2px 1px 6px 1px #00000040',
          background: '#FFFEFB',
          border: '1px solid #E5E1EC',
          height: isSmallScreen || showSide ? '100%' : 'calc(100% + 44px)',
          marginTop: isSmallScreen || showSide ? 0 : '-44px',
          filter: isSmallScreen && !showSide ? 'blur(4px)' : '',
          minWidth: isSmallScreen && !showSide ? '680px' : '0px',
        }}
        className={`flex flex-col items-center h-full w-full p-5 rounded-lg gap-5 justify-between ${
          isSmallScreen ? 'px-2' : 'px-5'
        }`}
      >
        <PulseLoader color="rgb(91, 71, 245)" size={10} />
      </div>
    );
  }
  return (
    <div
      style={{
        boxShadow: '2px 1px 6px 1px #00000040',
        background: '#FFFEFB',
        border: '1px solid #E5E1EC',
        height: isSmallScreen || showSide ? '100%' : 'calc(100% + 44px)',
        marginTop: isSmallScreen || showSide ? 0 : '-44px',
        filter: isSmallScreen && !showSide ? 'blur(4px)' : '',
        minWidth: isSmallScreen && !showSide ? '680px' : '0px',
      }}
      className={`flex flex-col items-center h-full w-full p-5 rounded-lg gap-5 justify-between ${
        isSmallScreen ? 'px-2' : 'px-5'
      }`}
    >
      {mess.length === 0 ? (
        <div className="flex flex-col items-center justify-center flex-grow">
          <XylicIcon width={60} height={60} className="fixed-size" />
          <div className={`flex p-2 mt-2 ${isSmallScreen ? 'gap-1' : 'gap-5'}`}>
            {suggestions.map((suggestion) => (
              <div
                key={suggestion}
                className="max-w-44 w-1/3 p-2 bg-[#FFFEFB] rounded-lg shadow-md border border-gray-200 text-center cursor-pointer transition duration-300 hover:shadow-lg"
                onClick={() => handleSend(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        </div>
      ) : (
        // <div className="flex flex-col gap-5 pr-5 w-4/5 max-h-[75vh] overflow-y-auto flex-grow">
        <div
          className={`flex flex-col ${isSmallScreen ? 'gap-2 p-2 ' : 'gap-5 pr-5 w-4/5'} max-h-[75vh] overflow-y-auto flex-grow`}
        >
          {mess.map((item: Message, index: number) => {
            const sender = users?.find(
              (user: any) => user.id === item.sender && user.name !== 'agent'
            );
            const isAgent = users?.find(
              (user: any) => user.id === item.sender && user.name === 'agent'
            );
            const userInitials =
              sender?.name?.[0]?.toUpperCase() || userName?.[0]?.toUpperCase();

            return (
              <div
                key={index}
                className={`flex flex-col gap-2 ${!isAgent ? 'ml-auto' : ''}`}
              >
                {!isAgent && (
                  <div className="flex justify-start gap-2">
                    <div
                      className="bg-transparent shadow-lg p-3 rounded-lg max-w-[900px]"
                      style={{
                        border: '1px solid #E5E1EC',
                      }}
                    >
                      <div className="break-words">
                        <Markdown>{item.message}</Markdown>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-center font-semibold"
                      style={{
                        width: isSmallScreen ? '20px' : '30px',
                        height: isSmallScreen ? '20px' : '30px',
                        backgroundColor: '#6b7280',
                        color: '#ffffff',
                        fontSize: isSmallScreen ? '10px' : '14px',
                        borderRadius: '50%',
                        flexShrink: 0,
                      }}
                    >
                      {userInitials}
                    </div>
                  </div>
                )}
                {isAgent && (
                  <>
                    <div className="flex gap-2 justify-start mt-2">
                      <XylicIcon
                        width={30}
                        height={30}
                        style={{
                          minWidth: isSmallScreen ? '20px' : '30px',
                          minHeight: isSmallScreen ? '20px' : '30px',
                        }}
                      />
                      <div
                        className="pl-8 p-3 rounded-lg max-w-[900px] overflow-hidden"
                        style={{
                          border: '1px solid #E5E1EC',
                        }}
                      >
                        <div className="break-words">
                          <Markdown>{item.message}</Markdown>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start">
                      <IconButton
                        style={{
                          position: 'relative',
                          marginLeft: isSmallScreen ? '30px' : '40px',
                          fontSize: '20px',
                          padding: '4px',
                          backgroundColor: 'transparent',
                          transition: 'all 0.3s ease-in-out',
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = '#f0f0f0')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            'transparent')
                        }
                        onClick={handleDislike(index)}
                      >
                        {item.dislike ? (
                          <ThumbDownAltRoundedIcon
                            style={{ fontSize: '20px' }} //color: '#d32f2f' }}
                          />
                        ) : (
                          <ThumbDownOffAltRoundedIcon
                            style={{ fontSize: '20px' }} //, color: '#6c757d' }}
                          />
                        )}
                      </IconButton>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {loadingMessage && tempLoading && (
            <div className="flex items-center gap-2 justify-start mt-2">
              <XylicIcon width={30} height={30} className="fixed-size" />
              <div className="pl-8 p-3 rounded-lg max-w-[80%] overflow-hidden">
                <PulseLoader color="rgb(91, 71, 245)" size={6} />
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      )}

      <div
        className={`flex flex-col items-center gap-2 ${isSmallScreen ? 'w-5/5' : 'w-4/5'} relative bottom-0 left-0 right-0`}
      >
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !(loadingMessage && tempLoading)) {
              handleSend(search);
            }
          }}
          value={search}
          variant="outlined"
          fullWidth
          placeholder="Message Xylic"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '16px',
            },
            '& .MuiOutlinedInput-root:hover fieldset': {
              borderColor: '2px solid rgb(69, 69, 69)',
            },
            '& .MuiOutlinedInput-root.Mui-focused fieldset': {
              border: search
                ? '2px solid rgb(48, 36, 139)'
                : '1px solid rgb(69, 69, 69)',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    search === ''
                      ? 'Message is empty'
                      : loadingMessage && tempLoading
                        ? 'Loading, please wait'
                        : 'Send message'
                  }
                  placement="top"
                >
                  <span>
                    <IconButton
                      className={`${
                        search && !(loadingMessage && tempLoading)
                          ? 'text-indigo-600'
                          : 'text-gray-400'
                      } cursor-pointer`}
                      onClick={() =>
                        !(loadingMessage && tempLoading) && handleSend(search)
                      }
                    >
                      <ArrowCircleUpIcon style={{ fontSize: '36px' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <div className="text-xs pl-2 pr-2 text-gray-500  ">
          Disclaimer: Xylic uses LLM and can make mistakes. Check important info
        </div>
      </div>
    </div>
  );
};
