/**
 * Generated by @openapi-codegen
 *
 * @version 0.1.0
 */
import * as reactQuery from "react-query";
import {
  useSalesMetricsContext,
  SalesMetricsContext,
} from "./salesMetricsContext";
import type * as Fetcher from "./salesMetricsFetcher";
import { salesMetricsFetch } from "./salesMetricsFetcher";
import type * as Schemas from "./salesMetricsSchemas";

export type ProductLevelFiltersFiltersProductLevelGetError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductLevelFiltersFiltersProductLevelGetVariables =
  SalesMetricsContext["fetcherOptions"];

export const fetchProductLevelFiltersFiltersProductLevelGet = (
  variables: ProductLevelFiltersFiltersProductLevelGetVariables,
  signal?: AbortSignal,
) =>
  salesMetricsFetch<
    void,
    ProductLevelFiltersFiltersProductLevelGetError,
    undefined,
    {},
    {},
    {}
  >({ url: "/filters/product_level", method: "get", ...variables, signal });

export const useProductLevelFiltersFiltersProductLevelGet = <TData = void,>(
  variables: ProductLevelFiltersFiltersProductLevelGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      void,
      ProductLevelFiltersFiltersProductLevelGetError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSalesMetricsContext(options);
  return reactQuery.useQuery<
    void,
    ProductLevelFiltersFiltersProductLevelGetError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/filters/product_level",
      operationId: "productLevelFiltersFiltersProductLevelGet",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProductLevelFiltersFiltersProductLevelGet(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetSalesDataSalesDataFilterSalesDataPostError =
  Fetcher.ErrorWrapper<{
    status: 422;
    payload: Schemas.HTTPValidationError;
  }>;

export type GetSalesDataSalesDataFilterSalesDataPostVariables = {
  body: Schemas.Filters;
} & SalesMetricsContext["fetcherOptions"];

export const fetchGetSalesDataSalesDataFilterSalesDataPost = (
  variables: GetSalesDataSalesDataFilterSalesDataPostVariables,
  signal?: AbortSignal,
) =>
  salesMetricsFetch<
    void,
    GetSalesDataSalesDataFilterSalesDataPostError,
    Schemas.Filters,
    {},
    {},
    {}
  >({
    url: "/sales_data/filter-sales-data",
    method: "post",
    ...variables,
    signal,
  });

export const useGetSalesDataSalesDataFilterSalesDataPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      GetSalesDataSalesDataFilterSalesDataPostError,
      GetSalesDataSalesDataFilterSalesDataPostVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useSalesMetricsContext();
  return reactQuery.useMutation<
    void,
    GetSalesDataSalesDataFilterSalesDataPostError,
    GetSalesDataSalesDataFilterSalesDataPostVariables
  >({
    mutationFn: (
      variables: GetSalesDataSalesDataFilterSalesDataPostVariables,
    ) =>
      fetchGetSalesDataSalesDataFilterSalesDataPost({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostQueryParams =
  {
    column_name: string;
  };

export type GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostError =
  Fetcher.ErrorWrapper<{
    status: 422;
    payload: Schemas.HTTPValidationError;
  }>;

export type GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostVariables =
  {
    body: Schemas.SalesFilters;
    queryParams: GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostQueryParams;
  } & SalesMetricsContext["fetcherOptions"];

export const fetchGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost =
  (
    variables: GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostVariables,
    signal?: AbortSignal,
  ) =>
    salesMetricsFetch<
      void,
      GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostError,
      Schemas.SalesFilters,
      {},
      GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostQueryParams,
      {}
    >({
      url: "/sales_data/available-filter-options",
      method: "post",
      ...variables,
      signal,
    });

export const useGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostError,
      GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useSalesMetricsContext();
  return reactQuery.useMutation<
    void,
    GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostError,
    GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostVariables
  >({
    mutationFn: (
      variables: GetAvailableFilterOptionsSalesDataAvailableFilterOptionsPostVariables,
    ) =>
      fetchGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetSalesMetricsSalesDataSalesMetricsPostError =
  Fetcher.ErrorWrapper<{
    status: 422;
    payload: Schemas.HTTPValidationError;
  }>;

export type GetSalesMetricsSalesDataSalesMetricsPostVariables = {
  body: Schemas.SalesKPIFilters;
} & SalesMetricsContext["fetcherOptions"];

export const fetchGetSalesMetricsSalesDataSalesMetricsPost = (
  variables: GetSalesMetricsSalesDataSalesMetricsPostVariables,
  signal?: AbortSignal,
) =>
  salesMetricsFetch<
    void,
    GetSalesMetricsSalesDataSalesMetricsPostError,
    Schemas.SalesKPIFilters,
    {},
    {},
    {}
  >({ url: "/sales_data/sales-metrics", method: "post", ...variables, signal });

export const useGetSalesMetricsSalesDataSalesMetricsPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      GetSalesMetricsSalesDataSalesMetricsPostError,
      GetSalesMetricsSalesDataSalesMetricsPostVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useSalesMetricsContext();
  return reactQuery.useMutation<
    void,
    GetSalesMetricsSalesDataSalesMetricsPostError,
    GetSalesMetricsSalesDataSalesMetricsPostVariables
  >({
    mutationFn: (
      variables: GetSalesMetricsSalesDataSalesMetricsPostVariables,
    ) =>
      fetchGetSalesMetricsSalesDataSalesMetricsPost({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type HelloGetError = Fetcher.ErrorWrapper<undefined>;

export type HelloGetVariables = SalesMetricsContext["fetcherOptions"];

export const fetchHelloGet = (
  variables: HelloGetVariables,
  signal?: AbortSignal,
) =>
  salesMetricsFetch<void, HelloGetError, undefined, {}, {}, {}>({
    url: "/",
    method: "get",
    ...variables,
    signal,
  });

export const useHelloGet = <TData = void,>(
  variables: HelloGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, HelloGetError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSalesMetricsContext(options);
  return reactQuery.useQuery<void, HelloGetError, TData>({
    queryKey: queryKeyFn({ path: "/", operationId: "helloGet", variables }),
    queryFn: ({ signal }) =>
      fetchHelloGet({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/filters/product_level";
      operationId: "productLevelFiltersFiltersProductLevelGet";
      variables: ProductLevelFiltersFiltersProductLevelGetVariables;
    }
  | {
      path: "/";
      operationId: "helloGet";
      variables: HelloGetVariables;
    };
