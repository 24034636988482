import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as BottomLogo } from "../../../assets/svg/loginLogo.svg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CustomButton } from "../../../components/atoms/button/button";
import styles from "../Register/register.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addToast } from "../../../store/toastSlice";
import { useDispatch } from "react-redux";
import FormInput from "../../../components/atoms/FormInputField";
import Loader from "../../../components/atoms/loader";
import { handleNavigate } from "../../../utils";

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [reqId, setReqId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [countdown, setCountdown] = React.useState(0);
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    handleNavigate(navigate, "/auth/forgot-password")
  }, [])
  // React.useEffect(() => {
  //     const user = JSON.parse(localStorage.getItem('user') || '{}');
  //     setEmail(user.email || '');
  //     setReqId(user.req_id || '');
  // }, []);

  React.useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmailValid) return;

    const data = new FormData(event.currentTarget);
  };

  const handleResendEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/forgot_password`,
        {
          email: email,
        }
      );
      setLoading(false);
      setCountdown(60);
      dispatch(
        addToast({
          id: Date.now(),
          type: "success",
          message: response.data.detail,
        })
      );
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          id: Date.now(),
          type: "error",
          message: "Error resending email. Please try again.",
        })
      );
      console.error("Error resending email:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "#5B47F5",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="max-sm:hidden"
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}


            >
              <p style={{ color: "white", fontWeight: 800, fontSize: "40px" }}>
                Xylic
              </p>
              <p style={{ color: "white", fontWeight: 400, fontSize: "20px" }}>
                Automate Insights & measurement
                <br /> for improved ad efficiency and revenue growth
              </p>
              <Button
                onClick={() => {
                  window.open("https://www.xylic.ai/");
                }}
                sx={{
                  padding: "10px",
                  background: "white",
                  borderRadius: "20px",
                  fontSize: "14px",
                  width: "135px",
                  height: "37px",
                  "&:hover": {
                    background: "white",
                  },
                }}
              >
                Read More
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{
              position: "fixed",
              top: 0,
              right: "33.3%",
              filter: "blur(200px)",
              background: "rgb(207, 200, 255)",
              height: "40%",
              width: "30%",
            }}
          />
          <Grid
            sx={{
              position: "fixed",
              bottom: 10,
              filter: "blur(200px)",
              background: "rgb(207, 200, 255)",
              height: "30%",
              width: "20%",
            }}
          />
          <Grid
            sx={{
              position: "fixed",
              bottom: 0,
            }}
            className={`${styles.logoInnerStyles} max-sm:hidden`}
          >
            <BottomLogo />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0px 80px",
            gap: "24px",
            background: "white",
          }}
          className={`${styles.registerContainer} max-sm:p-8`}
        >
          <Typography component="h1" variant="h5" className="headerText">
            Forgot Password
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            display={"flex"}
            gap={"24px"}
            flexDirection={"column"}
          >
            <FormInput
              variant="filled"
              size="small"
              type="email"
              label="Email"
              onChange={handleEmailChange}
              value={email}
              sx={{}}
            />
            <Grid sx={{ height: "200px" }} className={styles.emailVerifyHeightContainer2} />

            <Grid item>
              <Typography
                color={"#545454"}
                className="fontStyles"
                sx={{ marginLeft: "4px" }}
              >
                Didn’t receive the email?
              </Typography>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <CustomButton
                type="submit"
                disabled={!isEmailValid || countdown > 0 || loading}
                onClick={handleResendEmail}
              >
                {loading ? <Loader size={25} /> : "Submit"}
              </CustomButton>
            </Grid>
            <Grid container display={"flex"} justifyContent={"center"}>
              <Grid item>
                <Typography fontWeight={500} className="fontStyles">
                  {countdown > 0 && `Try again in ${countdown} seconds`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography fontWeight={500} className="fontStyles">
                  Back to
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color={"rgb(95, 70, 254)"}
                  className="fontStyles"
                  sx={{
                    marginLeft: "4px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
