import { Skeleton, Typography } from "@mui/material";
import { ReactComponent as DownIcon } from "../../../../../assets/svg/downicon.svg";
import { ReactComponent as UpIcon } from "../../../../../assets/svg/uparr.svg";
import styles from "./styles.module.scss";
import { addComma } from "../../../../../utils/index";

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SalesChart } from "./linechart";
import { useGetSalesMetricsSalesDataSalesMetricsPost } from "../../../../../api/sales-metrics-service/salesMetricsComponents";

const getTrendIcon = (value: number) => {
  if (value < 0) {
    return <DownIcon />;
  } else if (value === 0) {
    return null;
  } else {
    return <UpIcon />;
  }
};

const getTextColor = (value: number) => {
  if (value < 0) {
    return "#F60000";
  } else {
    return "#03A01C";
  }
};

export const KpiSection = () => {
  type PeriodMetrics = {
    number_of_buyers: number;
    unique_buyers: number;
    repeat_buyers: number;
    total_orders: number;
    repeat_order_units: number;
    repeat_customer_share: number;
  };
  
  type DailyBreakdownMetrics = {
    date: string; // ISO date string (e.g., "2024-09-07")
    number_of_buyers: number;
    total_orders: number;
    unique_buyers: number;
    total_items_shipped: number;
    repeat_order_units: number;
    repeat_customer_share: number;
  };
  
  type SalesMetricsResponse = {
    summary: {
      current_period: PeriodMetrics;
      previous_period: PeriodMetrics;
    };
    daily_breakdown: DailyBreakdownMetrics[];
  };
  

  const selectedFilter = useSelector(
    (state: any) => state?.headerContext?.dashboardDaysFilter
  );
  const { selectedFilters: selectedSidebarFilters } = useSelector(
    (state: any) => state?.filter
  );

  const [debouncedFilters, setDebouncedFilters] = useState(selectedSidebarFilters);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters(selectedSidebarFilters);
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedSidebarFilters]);

  const { mutate: fetchSalesMetrics, data, isLoading } =
    useGetSalesMetricsSalesDataSalesMetricsPost();

  useEffect(() => {
    fetchSalesMetrics({
      body: {
        time_period: selectedFilter,
        location: debouncedFilters?.location,
        order_type: debouncedFilters?.order_type,
        fulfilment_type: debouncedFilters?.fulfillment_type,
        asin: debouncedFilters?.asin,
      },
    });
  }, [fetchSalesMetrics, selectedFilter, debouncedFilters]);

  const kpiData = useMemo(() => {
    if (!data || isLoading) {
      return [
        {
          title: "Number of Buyers",
          content: <Skeleton variant="text" width={100} />,
          footer: <Skeleton variant="text" width={150} />,
          chartData: [],
        },
        {
          title: "Orders",
          content: <Skeleton variant="text" width={100} />,
          footer: <Skeleton variant="text" width={150} />,
          chartData: [],
        },
        {
          title: "Repeat Order Units",
          content: <Skeleton variant="text" width={100} />,
          footer: <Skeleton variant="text" width={150} />,
          chartData: [],
        },
        {
          title: "Repeat Customer Share",
          content: <Skeleton variant="text" width={100} />,
          footer: <Skeleton variant="text" width={150} />,
          chartData: [],
        },
      ];
    }

    const { summary: { current_period, previous_period }, daily_breakdown } = data as SalesMetricsResponse;
    // console.log('Daily', daily_breakdown)
    const numberBuyersBreakdown = daily_breakdown.map((day) => day.number_of_buyers);
    const totalOrdersBreakdown = daily_breakdown.map((day) => day.total_orders);
    const repeatOrderUnitsBreakdown = daily_breakdown.map((day) => day.repeat_order_units);
    const repeatCustomerShareBreakdown = daily_breakdown.map((day) => day.repeat_customer_share);

    // console.log('numberBuyersBreakdown', numberBuyersBreakdown);
    // console.log('totalOrdersBreakdown', totalOrdersBreakdown);
    // console.log('repeatOrderUnitsBreakdown', repeatOrderUnitsBreakdown);
    // console.log('repeatCustomerShareBreakdown', repeatCustomerShareBreakdown);


    const calculateTrend = (current: number, previous: number) => {
      if (!previous) return 0; // Prevent division by zero
      return Math.round(((current - previous) / previous) * 100);
    };

    return [
      {
        title: "Number of Buyers",
        content: addComma(current_period.number_of_buyers || 0),
        footer: (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {getTrendIcon(
              calculateTrend(
                current_period.number_of_buyers,
                previous_period.number_of_buyers
              )
            )}
            <Typography
              style={{
                color: getTextColor(
                  calculateTrend(
                    current_period.number_of_buyers,
                    previous_period.number_of_buyers
                  )
                ),
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              {calculateTrend(
                current_period.number_of_buyers,
                previous_period.number_of_buyers
              )}
              % <span style={{ color: "#6B7280" }}>vs prev = {addComma(previous_period.number_of_buyers || 0)}</span>
            </Typography>
          </div>
        ),
        

        chartData : numberBuyersBreakdown

      },
      {
        title: "Orders",
        content: addComma(current_period.total_orders || 0),
        footer: (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {getTrendIcon(
              calculateTrend(
                current_period.total_orders,
                previous_period.total_orders
              )
            )}
            <Typography
              style={{
                color: getTextColor(
                  calculateTrend(
                    current_period.total_orders,
                    previous_period.total_orders
                  )
                ),
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              {calculateTrend(
                current_period.total_orders,
                previous_period.total_orders
              )}
              % <span style={{ color: "#6B7280" }}>vs prev = {addComma(previous_period.total_orders || 0)}</span>
            </Typography>
          </div>
        ),
        chartData : totalOrdersBreakdown
        
      },
      {
        title: "Repeat Order Units",
        content: addComma(current_period.repeat_order_units || 0),
        footer: (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {getTrendIcon(
              calculateTrend(
                current_period.repeat_order_units,
                previous_period.repeat_order_units
              )
            )}
            <Typography
              style={{
                color: getTextColor(
                  calculateTrend(
                    current_period.repeat_order_units,
                    previous_period.repeat_order_units
                  )
                ),
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              {calculateTrend(
                current_period.repeat_order_units,
                previous_period.repeat_order_units
              )}
              % <span style={{ color: "#6B7280" }}>vs prev = {addComma(previous_period.repeat_order_units || 0)}</span>
            </Typography>
          </div>
        ),
        chartData : repeatOrderUnitsBreakdown
      },
      {
        title: "Repeat Customer Share",
        content: `${addComma(current_period.repeat_customer_share || 0)}%`,
        footer: (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {getTrendIcon(
              calculateTrend(
                current_period.repeat_customer_share,
                previous_period.repeat_customer_share
              )
            )}
            <Typography
              style={{
                color: getTextColor(
                  calculateTrend(
                    current_period.repeat_customer_share,
                    previous_period.repeat_customer_share
                  )
                ),
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              {calculateTrend(
                current_period.repeat_customer_share,
                previous_period.repeat_customer_share
              )}
              % <span style={{ color: "#6B7280" }}>vs prev = {addComma(previous_period.repeat_customer_share || 0)}%</span>
            </Typography>
          </div>
        ),
        chartData : repeatCustomerShareBreakdown
      },
    ];
  }, [data, isLoading]);

  return (
    <div className={styles.newSmallCards}>
      {kpiData.map((card, index) => (
        <div className={styles.newSmallCard} key={index}>
          <div className={styles.cardContent}>
            <Typography className={styles.cardTitle}>{card?.title}</Typography>
            <Typography className={styles.cardText}>{card?.content}</Typography>
            <div className={styles.cardFooter}>{card.footer}</div>
            {isLoading?(
              <Skeleton variant="text" width={100} />
            ):(<SalesChart label={card.title} data={card.chartData} />)}
            
          </div>
        </div>
      ))}
    </div>
  );
};
