import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  useMediaQuery,
} from '@mui/material';
import { Facebook, Podcasts } from '@mui/icons-material';
import axios from 'axios';
import {
  setLoadingP,
  setPermissions,
  setUserNameApi,
} from '../../../store/header/reducer';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { RootState } from '../../../store';

const EmptyDashboard = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState(''); // State to store the user's name
  const [loadingUserName, setLoadingUserName] = useState(true); // State for loading username
  const [currentTime, setCurrentTime] = useState(''); // State to store the current time
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        dispatch(setLoadingP(true));
        const token = localStorage.getItem('access_token'); // Get the bearer token from local storage
        const headers = { Authorization: `Bearer ${token}` };

        // Fetch user details
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/me`,
          { headers }
        );
        localStorage.setItem('name', userResponse.data.name);
        dispatch(setUserNameApi(userResponse.data.name));
        setUserName(userResponse.data.name); // Set the user's name
        setLoadingUserName(false); // Stop loading username

        // Fetch notifications
        const notificationsResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/notifications`,
          { headers }
        );
        setNotifications(notificationsResponse.data.messages);

        // Fetch permissions (console logging only)
        const permissionsResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/permissions`,
          { headers }
        );
        dispatch(setLoadingP(false));
        dispatch(setPermissions(permissionsResponse.data));
        setLoading(false);
      } catch (error) {
        dispatch(setLoadingP(false));
        console.error('Error fetching data:', error);
        setLoading(false);
        setLoadingUserName(false); // Stop loading even if there's an error
      }
    };

    fetchUserData();

    // Set current date and time
    const now = new Date();
    const options: any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const currentDate = now.toLocaleDateString(undefined, options);
    const currentTime = now.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    setCurrentTime(`${currentDate}, ${currentTime}`);
  }, []);

  const { showSide } = useSelector((state: RootState) => state.headerContext);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        padding: 5,
        fontFamily: 'Poppins, sans-serif',
        boxShadow: '2px 1px 6px 1px #00000040',
        background: '#FFFEFB',
        border: '1px solid #E5E1EC',
        display: 'block',
        height: isSmallScreen || showSide ? '100%' : 'calc(100% + 44px)',
        marginTop: isSmallScreen || showSide ? 0 : '-44px',
        filter: isSmallScreen && !showSide ? 'blur(4px)' : '',
        minWidth: isSmallScreen && !showSide ? '680px' : '0px',
      }}
      className={`w-full  p-5  rounded-lg overflow-scroll `}
    >
      <Grid sx={{ maxHeight: '100vh', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Box>
            {loadingUserName ? (
              <Skeleton
                variant="text"
                width={200}
                height={40}
                sx={{ marginBottom: 1 }}
              />
            ) : (
              <Typography
                variant="h4"
                sx={{
                  color: '#242533',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '2rem',
                  fontWeight: 600,
                }}
              >
                Hello, {userName} {/* Display the user's name */}
              </Typography>
            )}
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem' }}
            >
              {currentTime} {/* Display the current date and time */}
            </Typography>
          </Box>
        </Box>

        {/* Notifications */}
        <Box sx={{ marginBottom: 2 }}>
          {loading ? (
            // Skeleton loaders while loading
            <>
              <Skeleton
                variant="rectangular"
                height={50}
                sx={{ marginBottom: 2, borderRadius: 2 }}
              />
              <Skeleton
                variant="rectangular"
                height={50}
                sx={{ borderRadius: 2 }}
              />
            </>
          ) : (
            notifications.map((notification: any) => (
              <Box
                key={notification?.id}
                sx={{
                  backgroundColor: notification?.color || '#DDD9FC',
                  padding: 1.2,
                  borderRadius: 2,
                  marginBottom: 2,
                  fontFamily: 'Poppins, sans-serif',
                  display: 'flex',
                  gap: '5px',
                }}
              >
                <NotificationsIcon />
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem' }}
                >
                  {notification?.message}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Grid>

      {/* <Card sx={{ padding: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, fontFamily: 'Poppins, sans-serif', border: '1px solid #E0E0E0', backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1.5rem', fontWeight: 600 }}>
                  Go from beginner to Amazon expert!
                </Typography>
                <Typography color="text.secondary" paragraph sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                  Build your winning Amazon brand with the FBA training course trusted by 200k sellers – Freedom Ticket.
                </Typography>
                <Typography color="text.secondary" paragraph sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                  Hosted by 20+ top e-commerce experts, Freedom Ticket teaches you everything you need to succeed in 9 video modules. Learn more by exploring Freedom Ticket today!
                </Typography>
                <Button variant="contained" color="primary" sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem' }}>
                  Explore Freedom Ticket
                </Button>
              </CardContent>
              <Box sx={{ flex: '0 1 auto', marginLeft: 2, width: 700, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 4, border: '1px solid #E0E0E0', backgroundColor: '#F5F5F5' }}>
                <img
                  src="" // Replace with your placeholder image path
                  alt="Placeholder"
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 4 }}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={5}>
            <Card sx={{ padding: 2, fontFamily: 'Poppins, sans-serif', border: '1px solid #E0E0E0', backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1.5rem', fontWeight: 600 }}>
                  Join our community and learn the latest seller strategies
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem' }}>
                  Check out our Facebook page and our Serious Sellers Podcast for more tips and tricks on how to use Helium 10 to level up your business.
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ marginRight: 1, fontFamily: 'Poppins, sans-serif', fontSize: '1rem', display: 'flex', alignItems: 'center' }}
                  startIcon={<Facebook />}
                >
                  Join Our Facebook Group
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem', display: 'flex', alignItems: 'center', marginTop: '10px' }}
                  startIcon={<Podcasts />}
                >
                  Listen to Our Podcast
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card> */}
    </Box>
  );
};

export default EmptyDashboard;
