import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import styles from './styles.module.scss';

import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetSalesDataSalesDataFilterSalesDataPost } from '../../../../../api/sales-metrics-service/salesMetricsComponents';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const SalesChart = () => {
  type SalesDataResponse = {
    start_date: string;
    end_date: string;
    data: number[];
  };
  const selectedFilter = useSelector(
    (state: any) => state?.headerContext?.dashboardDaysFilter
  );
  const { selectedFilters: selectedSidebarFilters} = useSelector(
    (state: any) => state?.filter


  );

  const [debouncedFilters, setDebouncedFilters] = useState(selectedSidebarFilters);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters(selectedSidebarFilters);
    }, 1500); // debounce delay in ms

    return () => {
      clearTimeout(handler);
    };
  }, [selectedSidebarFilters]);
  
  const {
    mutate: fetchSalesData,
    data,
    isLoading
  } = useGetSalesDataSalesDataFilterSalesDataPost();
  // console.log('filter', selectedFilter);
  useEffect(() => {
    
    const filterKeys = [
      'super_category',
      'category',
      'sub_category',
      'segment',
      'sub_segment',
    ];
  
    let productLevelName = null;
    let productLevelValue = null;
  
    
    for (let i = filterKeys.length - 1; i >= 0; i--) {
      const key = filterKeys[i];
      if (debouncedFilters[key]?.length > 0) {
        productLevelName = key;
        productLevelValue = debouncedFilters[key];
        break; 
      }
    }
    fetchSalesData({
      body: {
        time_period: selectedFilter,
        currency_type: 'inr',
        location: debouncedFilters?.location,
        promotions_type: debouncedFilters?.promotions_type,
        order_type: debouncedFilters?.order_type,
        fulfillment_type: debouncedFilters?.fulfillment_type,
        brand: debouncedFilters?.brand,
        asin: debouncedFilters?.asin,
        ...(productLevelName && {
          product_level_name: productLevelName,
          product_level: productLevelValue,
        }),
      },
    });
  }, [fetchSalesData, selectedFilter, debouncedFilters]);
  
// console.log('filter', isLoading, data);
  const chartData = useMemo(() => {
    if (!data || isLoading) return null;

    const salesData = data as SalesDataResponse;

    const labels = Array.from(
      { length: salesData.data.length },
      (_, index) =>
        new Date(salesData.start_date).setDate(
          new Date(salesData.start_date).getDate() + index
        ) &&
        new Date(
          new Date(salesData.start_date).setDate(
            new Date(salesData.start_date).getDate() + index
          )
        ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    );

    return {
      labels,
      datasets: [
        {
          label: 'Sales Data',
          data: salesData.data,
          borderColor: '#3AA9F4',
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, '#8CD2F9');
            gradient.addColorStop(1, 'rgba(140, 210, 249, 0)');
            return gradient;
          },
          fill: true,
          borderWidth: 2,
          pointRadius: 4,
          tension: 0.3,
        },
      ],
    };
  }, [data, isLoading]);

  const options: any = {
    responsive: true,
    elements: {
      point: {
        radius: 4,
      },
    },
    interaction: {
      mode: 'nearest',
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        min: 0,
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          callback: function (value: number) {
            return `$${(value / 1000).toFixed(0)}k`;
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.sales}>Sales</div>
        </div>
        {/* <div className={styles.item}>
          <div className={styles.profit}>Profit</div>
        </div> */}
      </div>
      {chartData && !isLoading ? (
        <Chart type="line" data={chartData} options={options} />
      ) : (
        <div className="p-4">
          <Skeleton
            variant="rectangular"
            height={20}
            sx={{ borderRadius: 2 }}
          />
          <Skeleton
            variant="rectangular"
            height={10}
            sx={{ borderRadius: 2, mt: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={10}
            sx={{ borderRadius: 2, mt: 1 }}
          />
        </div>
      )}
    </div>
  );
};
