/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React from 'react';

interface IEndSession {
    closePopup: () => void;
}

const TermServices = ({ closePopup }: IEndSession) => {
    const brandList = [
        'Account Information: Brand is responsible for maintaining the accuracy and confidentiality of its account information.',
        'Usage: Brand agrees to use Xylic in accordance with all applicable laws and regulations, and in a manner that does not infringe upon the rights of others. ',
        'Intellectual Property: Brand acknowledges that Xylic and its content are protected by intellectual property laws. Brand agrees not to reproduce, modify, distribute, or otherwise exploit any part of Xylic without written permission.',
        'Data Privacy: Brand agrees to comply with all applicable data privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA)'
    ];

    const prohibitedList = [
        "Termination: Xylic reserves the right to terminate Brand's access to the services at any time, with or without cause.",
        "Modifications: Xylic may modify these Terms and Conditions at any time. Brand's continued use of Xylic after such modifications constitutes acceptance of the modified terms.",
        'Disclaimer of Warranties: Xylic provides the services on an "as is" and "as available" basis, without warranties of any kind. Xylic does not guarantee the accuracy, completeness, or reliability of the information provided through its services.',
        'Using Spotify for illegal activities',
        'Using the service to expose proprietary or confidential information of a third party or yourself that\'s not intended to be shown to the world',
        "Limitation of Liability: In no event shall Xylic be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with Brand's use of the services."
    ];

    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                top: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
                zIndex: 9999,
                backgroundColor: 'rgba(0, 0, 0, 0.559)',
                padding: '16px',
            }}
        >
            <div
                style={{
                    backgroundColor: '#1F2B56',
                    borderRadius: '16px',
                    padding: '24px',
                    maxWidth: '450px',
                    width: '100%',
                    color: 'white',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    overflowY: 'auto',
                    maxHeight: '90vh',
                }}
            >
                <h2
                    style={{
                        fontSize: '22px',
                        fontWeight: '700',
                        marginBottom: '16px',
                        textAlign: 'left',
                    }}
                >
                    Terms and Conditions
                </h2>

                <div
                    style={{
                        fontSize: '12px',
                        lineHeight: '20px',
                        marginBottom: '16px',
                    }}
                >
                    <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>1.Acceptance</h3>
                    <p>By accessing or using Xylic's services, you ("Brand") agree to be bound by these Terms and Conditions. If you do not agree to these terms, you are not authorized to use Xylic.</p>
                </div>

                <div
                    style={{
                        fontSize: '12px',
                        lineHeight: '20px',
                        marginBottom: '16px',
                    }}
                >
                    <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>2.Xylic Services</h3>
                    <p>Xylic provides a suite of tools and services designed to assist brands in optimizing their Amazon selling strategy. These services may include, but are not limited to: keyword research, product listing optimization, sales analytics, and competitor analysis.</p>
                </div>

                <div
                    style={{
                        fontSize: '12px',
                        lineHeight: '20px',
                        marginBottom: '16px',
                    }}
                >
                    <h3 style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Brand Responsibilities</h3>
                    <ul style={{ paddingLeft: '20px', marginBottom: '16px' }}>
                        {brandList.map((item, index) => (
                            <li key={index} style={{ marginBottom: '8px' }}>{item}</li>
                        ))}
                    </ul>
                </div>

                <div
                    style={{
                        fontSize: '12px',
                        lineHeight: '20px',
                        marginBottom: '16px',
                    }}
                >
                    <h3 style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Xylic's Rights</h3>
                    <ul style={{ paddingLeft: '20px' }}>
                        {prohibitedList.map((item, index) => (
                            <li key={index} style={{ marginBottom: '8px' }}>{item}</li>
                        ))}
                    </ul>
                </div>

                <div
                    style={{
                        fontSize: '12px',
                        lineHeight: '20px',
                        marginBottom: '16px',
                    }}
                >
                    <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>Contact Information</h3>
                    <p>connect@xylic.ai</p>
                    <p>By using Xylic, Brand agrees to these Terms and Conditions.</p>
                </div>


                <button
                    onClick={closePopup}
                    style={{
                        backgroundColor: '#6247EA',
                        color: 'white',
                        border: 'none',
                        borderRadius: '8px',
                        padding: '10px 24px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        display: 'block',
                        marginLeft: 'auto',
                    }}
                >
                    Close
                </button>

            </div>
        </div>
    );
};

export default TermServices;
