import React, { useMemo, useState } from "react";
import { Skeleton } from "@mui/material";


import styles from './sidebarLayout.module.scss';
import dropDown from '../../../assets/svg/sidebar/dropDown.svg';
import { useDispatch, useSelector } from "react-redux";
import { toggleFilterDropdown, updateFilterOptionsCache, toggleFilterOption } from "../../../store/sidebar/filterSlice";
import { useGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost } from "../../../api/sales-metrics-service/salesMetricsComponents";

interface FilterDropdownProps {
  label: string;
}

const FilterDropdownComponent: React.FC<FilterDropdownProps> = ({ label }) => {
  const dispatch = useDispatch();

  const filterMapping: Record<string, string> = {
    Location: 'location',
    'Fulfillment Type': 'fulfilment_type',
    'Promotions Type': 'promotion_type',
    'Order Type': 'order_type',
    'Super Category': 'super_category',
    Category: 'category',
    'Sub-Category': 'sub_category',
    Segment: 'segment',
    'Sub-Segment': 'sub_segment',
    Brand: 'brand',
    Item: 'asin',
  };

  const { selectedFilters, filterOptionsCache, filterDropdownStates } = useSelector(
    (state: any) => state.filter
  );
  console.log('selectedFiltersIn sidebar comp', selectedFilters);
  const { mutate: salesFilterValues, isLoading: loadingFilterValues } = useGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost();
  const filterHierarchy = ['Location', 'Fulfillment Type', 'Promotions', 'Order Type', 'Super Category', 'Category', 'Sub-Category', 'Segment', 'Sub-Segment', 'Brand', 'Item'];

  const handleToggleFilterDropdown = (filterName: string) => {
    Object.keys(filterDropdownStates).forEach((key) => {
      if (key !== filterName && filterDropdownStates[key]) {
        dispatch(toggleFilterDropdown(key));
      }
    });

    dispatch(toggleFilterDropdown(filterName));

    if (!filterDropdownStates[filterName]) {
      handleSalesFilterClick(filterName);
    }
  };

  
  const handleOptionToggle = (label: string, option: string) => {
    const columnName = filterMapping[label];
    dispatch(toggleFilterOption({ label: columnName, option }));
  };
  const selectedHeaderFilter = useSelector(
    (state: any) => state?.headerContext?.dashboardDaysFilter 
  );

  const handleSalesFilterClick = (filter: string) => {
    const columnName = filterMapping[filter];
    
    // if (filterOptionsCache[filter]) return;  // refetch all the time

    salesFilterValues(
      {
        body: {
          time_period: selectedHeaderFilter,
          currency_type: 'inr',
          ...selectedFilters,
        },
        queryParams: { column_name: columnName },
      },
      {
        onSuccess: (data: any) => {
          dispatch(updateFilterOptionsCache({ filter, options: data?.values || [] }));
        },
        onError: (error) => {
          console.error('Error fetching options:', error);
        },
      }
    );
  };

  const options = useMemo(() => filterOptionsCache[label] || [], [filterOptionsCache, label]);
  const [searchTerm, setSearchTerm] = useState<string>("");
   const filteredOptions = useMemo(() => {
    return options.filter((option: string) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  return (
    <div className={styles.filterDropdownContainer} onClick={(e) => e.stopPropagation()}>
      <img
        src={dropDown}
        alt="options"
        onClick={() => handleToggleFilterDropdown(label)}
        className={styles.smallIcon}
      />
      {filterDropdownStates[label] && (
        <div className={`${styles.filterDropdown} ${filterDropdownStates[label] ? styles.visible : ''}`}>
          {loadingFilterValues ? (
            <Skeleton variant="rectangular" height={20} sx={{ borderRadius: 2, padding: "10px 0" }} />
          ) : (
            <>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              {filteredOptions.length === 0 ? (
                <div>No options found</div>
              ) : (
                filteredOptions.map((option: string) => (
                  <div className={styles.filterOption} key={option}>
                    <input
                      type="checkbox"
                      // checked={selectedFilters[filterMapping[label]]?.includes(option) || false }
                      checked={
                        filterHierarchy.includes(label) && (
                          selectedFilters[filterMapping[label]]?.includes(option) || 
                          (
                            !selectedFilters[filterMapping[label]]?.includes(option) &&
                            filterHierarchy
                              .slice(filterHierarchy.indexOf(label) + 1)
                              .some((lowerFilter) =>
                                (selectedFilters[filterMapping[lowerFilter]] || []).length > 0
                              )
                          )
                        )
                      }
                      onChange={() => handleOptionToggle(label, option)}
                    />
                    {option}
                  </div>
                ))
              )}
            </>
            )}
        </div>
      )}
    </div>
  );
};

export default FilterDropdownComponent;
