import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as XylicLogo } from "../../../assets/svg/xylicLogo.svg";
import { ReactComponent as BottomLogo } from "../../../assets/svg/loginLogo.svg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormInput from "../../../components/atoms/FormInputField";
import { CustomButton } from "../../../components/atoms/button/button";
import styles from "./register.module.scss";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { addToast } from "../../../store/toastSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/atoms/loader";
import { apiRequest } from "../../../services/onboard";

const defaultTheme = createTheme();

export default function EmailVerificationPage() {
  const [email, setEmail] = React.useState("");
  const [reqId, setReqId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [countdown, setCountdown] = React.useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id: any = queryParams.get("id");
  const dispatch = useDispatch();
  React.useEffect(() => {
    const email = localStorage.getItem(id) || "";
    setEmail(email || "");
    setReqId(id || "");
  }, []);

  React.useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const handleResendEmail = async () => {
    try {
      if (email) {
        await apiRequest({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/auth/resend_verification_email`,
          data: { req_id: reqId },
          dispatch,
          setLoading,
          successMessage: "Verification email resent successfully!",
          errorMessage: "Error resending email. Please try again.",
        });
        setCountdown(60);
      } else{
        dispatch(
          addToast({
              id: Date.now(),
              type: 'error',
              message: 'Something went wrong. Please try again.',
          }),
      );
      }
      // You can add additional logic to handle the response, like showing a success message.
    } catch (error) {
      // Error handling is already done in apiRequest
      console.error("Error resending email:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "#5B47F5",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="max-sm:hidden"
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ color: "white", fontWeight: 800, fontSize: "40px" }}>
                Xylic
              </p>
              <p style={{ color: "white", fontWeight: 400, fontSize: "20px" }}>
                Automate Insights & measurement
                <br /> for improved ad efficiency and revenue growth
              </p>
              <Button
                sx={{
                  padding: "10px",
                  background: "white",
                  borderRadius: "20px",
                  fontSize: "14px",
                  width: "135px",
                  height: "37px",

                  "&:hover": {
                    background: "white",
                  },
                }}
                onClick={() => {
                  window.open("https://www.xylic.ai/");
                }}
              >
                Read More
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{
              position: "fixed",
              top: 0,
              right: "33.3%",
              filter: "blur(200px)",
              background: "rgb(207, 200, 255)",
              height: "40%",
              width: "30%",
            }}
          />
          <Grid
            sx={{
              position: "fixed",
              bottom: 10,
              filter: "blur(200px)",
              background: "rgb(207, 200, 255)",
              height: "30%",
              width: "20%",
            }}
          />
          <Grid
            sx={{
              position: "fixed",
              bottom: 0,
            }}
            className={styles.logoInnerStyles}
          >
            <BottomLogo />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0px 80px",
            gap: "24px",
            background: "white",
          }}
          className={`${styles.registerContainer} max-sm:p-8`}
        >
          {/* <Grid>
            <XylicLogo />
          </Grid> */}
          <Typography component="h1" variant="h5" className="headerText">
            Check your Email
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            display={"flex"}
            gap={"24px"}
            flexDirection={"column"}
          >
            <Grid>
              We have sent a verification email to{" "}
              {email || "your email address"}
            </Grid>
            <Grid sx={{ height: "200px" }} className={styles.emailVerifyHeightContainer} />

            <Grid item>
              <Typography
                color={"#545454"}
                className="fontStyles"
                sx={{ marginLeft: "4px" }}
              >
                Didn’t receive the email?
              </Typography>
            </Grid>
            <CustomButton
              onClick={handleResendEmail}
              disabled={countdown > 0 || loading}
            >
              {loading ? <Loader size={25} /> : "Resend Email"}
            </CustomButton>
            <Grid container display={"flex"} justifyContent={"center"}>
              <Grid item>
                <Typography fontWeight={500} className="fontStyles">
                  {countdown > 0 && `Try again in ${countdown} seconds`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography fontWeight={500} className="fontStyles">
                  Back to
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color={"rgb(95, 70, 254)"}
                  className="fontStyles"
                  sx={{
                    marginLeft: "4px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
