import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: false
  },
  scales: {
    y: {
        display: false,
        min: 0,
        ticks: {
            display: false
        },
        grid: {
            display: false,
        },
    },
    x: {
        display: false,
        ticks: {
            display: false
        },
        grid: {
            display: false,
        },
    }
  }
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: [900, 450, 600, 800, 550, 700, 300],
      borderColor: "#85D8DD",
      backgroundColor: "rgb(133, 216, 221, 0.2)",
      pointRadius: 0
    },
  ],
};

export function ArialChart({height='1000px',width='700px'}: any) {
  return <Line options={options} data={data} width={width} height={height} />;
}
