/**
 * Generated by @openapi-codegen
 *
 * @version 0.1.0
 */
import * as reactQuery from 'react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type ProductLevelFiltersFiltersProductLevelGetError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductLevelFiltersFiltersProductLevelGetVariables =
  ApiContext['fetcherOptions'];

export const fetchProductLevelFiltersFiltersProductLevelGet = (
  variables: ProductLevelFiltersFiltersProductLevelGetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    ProductLevelFiltersFiltersProductLevelGetError,
    undefined,
    {},
    {},
    {}
  >({ url: '/filters/product_level', method: 'get', ...variables, signal });

export const useProductLevelFiltersFiltersProductLevelGet = <TData = void>(
  variables: ProductLevelFiltersFiltersProductLevelGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      void,
      ProductLevelFiltersFiltersProductLevelGetError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    void,
    ProductLevelFiltersFiltersProductLevelGetError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/filters/product_level',
      operationId: 'productLevelFiltersFiltersProductLevelGet',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProductLevelFiltersFiltersProductLevelGet(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type InitiateChatChatInitiateChatGetError =
  Fetcher.ErrorWrapper<undefined>;

export type InitiateChatChatInitiateChatGetVariables =
  ApiContext['fetcherOptions'];

export const fetchInitiateChatChatInitiateChatGet = (
  variables: InitiateChatChatInitiateChatGetVariables,
  signal?: AbortSignal
) =>
  apiFetch<void, InitiateChatChatInitiateChatGetError, undefined, {}, {}, {}>({
    url: '/chat/initiate_chat',
    method: 'get',
    ...variables,
    signal,
  });

export const useInitiateChatChatInitiateChatGet = <TData = void>(
  variables: InitiateChatChatInitiateChatGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      void,
      InitiateChatChatInitiateChatGetError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<void, InitiateChatChatInitiateChatGetError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/chat/initiate_chat',
        operationId: 'initiateChatChatInitiateChatGet',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchInitiateChatChatInitiateChatGet(
          { ...fetcherOptions, ...variables },
          signal
        ),
      ...options,
      ...queryOptions,
    }
  );
};

export type ListChatsChatChatsGetError = Fetcher.ErrorWrapper<undefined>;

export type ListChatsChatChatsGetVariables = ApiContext['fetcherOptions'];

export const fetchListChatsChatChatsGet = (
  variables: ListChatsChatChatsGetVariables,
  signal?: AbortSignal
) =>
  apiFetch<void, ListChatsChatChatsGetError, undefined, {}, {}, {}>({
    url: '/chat/chats',
    method: 'get',
    ...variables,
    signal,
  });

export const useListChatsChatChatsGet = <TData = void>(
  variables: ListChatsChatChatsGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, ListChatsChatChatsGetError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<void, ListChatsChatChatsGetError, TData>({
    queryKey: queryKeyFn({
      path: '/chat/chats',
      operationId: 'listChatsChatChatsGet',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListChatsChatChatsGet({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetChatChatChatIdGetPathParams = {
  chatId: string;
};

export type GetChatChatChatIdGetError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.HTTPValidationError;
}>;

export type GetChatChatChatIdGetVariables = {
  pathParams: GetChatChatChatIdGetPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetChatChatChatIdGet = (
  variables: GetChatChatChatIdGetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    GetChatChatChatIdGetError,
    undefined,
    {},
    {},
    GetChatChatChatIdGetPathParams
  >({ url: '/chat/{chatId}', method: 'get', ...variables, signal });

export const useGetChatChatChatIdGet = <TData = void>(
  variables: GetChatChatChatIdGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetChatChatChatIdGetError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<void, GetChatChatChatIdGetError, TData>({
    queryKey: queryKeyFn({
      path: '/chat/{chatId}',
      operationId: 'getChatChatChatIdGet',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetChatChatChatIdGet({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ArchiveChatChatChatIdArchivePatchPathParams = {
  chatId: string;
};

export type ArchiveChatChatChatIdArchivePatchError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.HTTPValidationError;
}>;

export type ArchiveChatChatChatIdArchivePatchVariables = {
  pathParams: ArchiveChatChatChatIdArchivePatchPathParams;
} & ApiContext['fetcherOptions'];

export const fetchArchiveChatChatChatIdArchivePatch = (
  variables: ArchiveChatChatChatIdArchivePatchVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    ArchiveChatChatChatIdArchivePatchError,
    undefined,
    {},
    {},
    ArchiveChatChatChatIdArchivePatchPathParams
  >({ url: '/chat/{chatId}/archive', method: 'patch', ...variables, signal });

export const useArchiveChatChatChatIdArchivePatch = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      ArchiveChatChatChatIdArchivePatchError,
      ArchiveChatChatChatIdArchivePatchVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    void,
    ArchiveChatChatChatIdArchivePatchError,
    ArchiveChatChatChatIdArchivePatchVariables
  >({
    mutationFn: (variables: ArchiveChatChatChatIdArchivePatchVariables) =>
      fetchArchiveChatChatChatIdArchivePatch({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ArchiveChatChatChatIdUnArchivePatchPathParams = {
  chatId: string;
};

export type ArchiveChatChatChatIdUnArchivePatchError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.HTTPValidationError;
}>;

export type ArchiveChatChatChatIdUnArchivePatchVariables = {
  pathParams: ArchiveChatChatChatIdUnArchivePatchPathParams;
} & ApiContext['fetcherOptions'];

export const fetchArchiveChatChatChatIdUnArchivePatch = (
  variables: ArchiveChatChatChatIdUnArchivePatchVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    ArchiveChatChatChatIdUnArchivePatchError,
    undefined,
    {},
    {},
    ArchiveChatChatChatIdUnArchivePatchPathParams
  >({
    url: '/chat/{chatId}/un-archive',
    method: 'patch',
    ...variables,
    signal,
  });

export const useArchiveChatChatChatIdUnArchivePatch = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      ArchiveChatChatChatIdUnArchivePatchError,
      ArchiveChatChatChatIdUnArchivePatchVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    void,
    ArchiveChatChatChatIdUnArchivePatchError,
    ArchiveChatChatChatIdUnArchivePatchVariables
  >({
    mutationFn: (variables: ArchiveChatChatChatIdUnArchivePatchVariables) =>
      fetchArchiveChatChatChatIdUnArchivePatch({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DislikeMessageChatChatIdMessagesMessageIndexDislikePatchPathParams =
  {
    chatId: string;
    messageIndex: number;
  };

export type DislikeMessageChatChatIdMessagesMessageIndexDislikePatchError =
  Fetcher.ErrorWrapper<{
    status: 422;
    payload: Schemas.HTTPValidationError;
  }>;

export type DislikeMessageChatChatIdMessagesMessageIndexDislikePatchVariables =
  {
    pathParams: DislikeMessageChatChatIdMessagesMessageIndexDislikePatchPathParams;
  } & ApiContext['fetcherOptions'];

export const fetchDislikeMessageChatChatIdMessagesMessageIndexDislikePatch = (
  variables: DislikeMessageChatChatIdMessagesMessageIndexDislikePatchVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    DislikeMessageChatChatIdMessagesMessageIndexDislikePatchError,
    undefined,
    {},
    {},
    DislikeMessageChatChatIdMessagesMessageIndexDislikePatchPathParams
  >({
    url: '/chat/{chatId}/messages/{messageIndex}/dislike',
    method: 'patch',
    ...variables,
    signal,
  });

export const useDislikeMessageChatChatIdMessagesMessageIndexDislikePatch = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      DislikeMessageChatChatIdMessagesMessageIndexDislikePatchError,
      DislikeMessageChatChatIdMessagesMessageIndexDislikePatchVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    void,
    DislikeMessageChatChatIdMessagesMessageIndexDislikePatchError,
    DislikeMessageChatChatIdMessagesMessageIndexDislikePatchVariables
  >({
    mutationFn: (
      variables: DislikeMessageChatChatIdMessagesMessageIndexDislikePatchVariables
    ) =>
      fetchDislikeMessageChatChatIdMessagesMessageIndexDislikePatch({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DeleteChatChatChatIdDeletePatchPathParams = {
  chatId: string;
};

export type DeleteChatChatChatIdDeletePatchError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.HTTPValidationError;
}>;

export type DeleteChatChatChatIdDeletePatchVariables = {
  pathParams: DeleteChatChatChatIdDeletePatchPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteChatChatChatIdDeletePatch = (
  variables: DeleteChatChatChatIdDeletePatchVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    void,
    DeleteChatChatChatIdDeletePatchError,
    undefined,
    {},
    {},
    DeleteChatChatChatIdDeletePatchPathParams
  >({ url: '/chat/{chatId}/delete', method: 'patch', ...variables, signal });

export const useDeleteChatChatChatIdDeletePatch = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      DeleteChatChatChatIdDeletePatchError,
      DeleteChatChatChatIdDeletePatchVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    void,
    DeleteChatChatChatIdDeletePatchError,
    DeleteChatChatChatIdDeletePatchVariables
  >({
    mutationFn: (variables: DeleteChatChatChatIdDeletePatchVariables) =>
      fetchDeleteChatChatChatIdDeletePatch({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type HelloGetError = Fetcher.ErrorWrapper<undefined>;

export type HelloGetVariables = ApiContext['fetcherOptions'];

export const fetchHelloGet = (
  variables: HelloGetVariables,
  signal?: AbortSignal
) =>
  apiFetch<void, HelloGetError, undefined, {}, {}, {}>({
    url: '/',
    method: 'get',
    ...variables,
    signal,
  });

export const useHelloGet = <TData = void>(
  variables: HelloGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, HelloGetError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<void, HelloGetError, TData>({
    queryKey: queryKeyFn({ path: '/', operationId: 'helloGet', variables }),
    queryFn: ({ signal }) =>
      fetchHelloGet({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/filters/product_level';
      operationId: 'productLevelFiltersFiltersProductLevelGet';
      variables: ProductLevelFiltersFiltersProductLevelGetVariables;
    }
  | {
      path: '/chat/initiate_chat';
      operationId: 'initiateChatChatInitiateChatGet';
      variables: InitiateChatChatInitiateChatGetVariables;
    }
  | {
      path: '/chat/chats';
      operationId: 'listChatsChatChatsGet';
      variables: ListChatsChatChatsGetVariables;
    }
  | {
      path: '/chat/{chatId}';
      operationId: 'getChatChatChatIdGet';
      variables: GetChatChatChatIdGetVariables;
    }
  | {
      path: '/';
      operationId: 'helloGet';
      variables: HelloGetVariables;
    };
