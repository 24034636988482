import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import styles from './styles.module.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../../services/onboard';
import { makeid } from '../../../utils';
import Loader from '../../../components/atoms/loader';
import { addToast } from '../../../store/toastSlice';
import { Box } from '@mui/material';

const defaultTheme = createTheme();

const AmazonAdsComponent = () => {
  const navigate = useNavigate();
  const [marketplace, setMarketplace] = React.useState('');
  const [marketplaces, setMarketplaces] = React.useState<any>([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const conn = queryParams.get('conn');
  const region = queryParams.get('region') as 'NA' | 'EU' | 'FE' | null;
  console.log('region', region);

  const dispatch = useDispatch();
  const handleGoToAmazon = async () => {
    if (region) {
      const url = await getTheUrl(region);
      console.log('url', url);
      if (url?.data?.auth_url) {
        window.location.href = url?.data?.auth_url;
      }
    }
  };

  const handleCancel = async () => {
    try {
      const token = localStorage.getItem('access_token'); // Get the bearer token from local storage

      if (!token) {
        throw new Error('No access token found');
      }

      // Call the onboarding finished endpoint using the utility function
      await apiRequest({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}/auth/onboarding_finished`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        token: localStorage.getItem('access_token') || '',
        dispatch,
        errorMessage: 'Please try again.',
      });

      // Set the onboarding status in localStorage and navigate to the home route
      localStorage.setItem('is_onboarded', 'true');
      if (conn === 'false') {
        navigate('/home');
      } else {
        navigate('/connections');
      }
    } catch (error) {
      console.error('Error during cancel operation:', error);
      dispatch(
        addToast({
          id: Date.now(),
          type: 'error',
          message: 'Please try again.',
        })
      );
    }
  };

  const getTheUrl = async (region: any) => {
    setLoading(true);

    try {
      const token = localStorage.getItem('access_token'); // Retrieve token from localStorage

      if (!token) {
        throw new Error('No token found');
      }

      const response = await apiRequest({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}/amazon_ads/api/amazon_ads/auth_url?region=${region}`,
        // url: `http://localhost:8000/amazon_ads/api/amazon_ads/auth_url?region=${region} `,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        token: localStorage.getItem('access_token') || '',

        dispatch,
        errorMessage: 'Failed to retrieve URL. Please try again.',
      });
      // set region in localStorage
      localStorage.setItem('amazon_ads_region', region);

      // Return the response to the caller
      return response;
    } catch (err: any) {
      setError(err?.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const regionDisplayMap: { [key in 'NA' | 'EU' | 'FE']: string } = {
    NA: 'NA (North America)',
    EU: 'EU (Europe)',
    FE: 'FE (Far East)',
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid sx={{ height: '100vh', overflow: 'auto' }}>
        <Grid
          container
          component="main"
          sx={{ overflow: 'auto' }}
          className={`font-poppins ${styles.mainCont}`}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#5B47F5',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '0 40px',
            }}
            className="max-sm:hidden"
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <Grid
                sx={{
                  gap: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: 'white',
                    fontWeight: 800,
                    fontSize: '36px',
                    lineHeight: '44px',
                    letterSpacing: '0.5px',
                  }}
                >
                  Welcome to Xylic
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '28px',
                  }}
                >
                  Streamline your business processes and boost productivity.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                position: 'fixed',
                top: 0,
                right: '33.3%',
                filter: 'blur(200px)',
                // background: 'rgb(207, 200, 255)',
                height: '40%',
                width: '30%',
              }}
            />
            <Grid
              sx={{
                position: 'fixed',
                bottom: 10,
                filter: 'blur(200px)',
                // background: 'rgb(207, 200, 255)',
                height: '30%',
                width: '20%',
              }}
            />
            <Grid
              sx={{
                position: 'fixed',
                bottom: -30,
                left: -30,
              }}
            >
              {/* Existing OnboardLogo */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            sx={{
              gap: '12px',
              padding: '40px',
              paddingLeft: '100px',
              position: 'relative',
            }}
            className="max-sm:px-8 max-sm:w-full"
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                height: '100%',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  marginBottom: '0px',
                }}
              >
                Add an Amazon Ads Account
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  marginBottom: '16px',
                }}
              >
                Taking you to Amazon Ads Authentication
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '32px',
                  textAlign: 'start',
                  color: '#555555',
                  width: '84%',
                }}
                className="max-sm:w-full"
              >
                You will be taken to an Login With Amazon page to authorize
                Xylic access to your Amazon Ads account.
              </Typography>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ marginBottom: '16px' }}
              >
                <Grid item>
                  <Box
                    sx={{
                      width: '300px',
                      height: '40px',
                      fontFamily: 'Poppins',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  >
                    {region ? regionDisplayMap[region] : 'Unknown Region'}
                  </Box>
                </Grid>
                <Grid item>
                  <a
                    href="#"
                    onClick={handleGoToAmazon}
                    style={{
                      display: 'inline-block',
                      textDecoration: 'none',
                      color: '#1976d2',
                      fontWeight: 'bold',
                    }}
                  >
                    {loading ? <Loader /> : 'Go to Amazon'}
                  </a>
                </Grid>
              </Grid>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  color: '#555555',
                  position: { xs: 'fixed', sm: 'absolute' }, // Position button absolutely
                  bottom: '40px', // Adjust distance from bottom as needed
                  left: '40px',
                  height: '40px', // Adjust distance from left as needed
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default AmazonAdsComponent;
