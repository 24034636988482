import React from "react";
import styles from "./styles.module.scss";
import { Typography } from "@mui/material";

const SmallCard = ({card, index}: any) => {
  return (
    <div className={styles["small-card"]} key={index}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#8E8B9D",
          }}
        >
          {card?.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "22px",
            color: "#181D25",
          }}
        >
          {card?.content}
        </Typography>
        {card?.footer}
      </div>
    </div>
  );
};

export default SmallCard;
