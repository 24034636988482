import axios from 'axios';
import { Dispatch } from 'redux';
import { addToast } from '../store/toastSlice';

interface ApiRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    data?: any;
    token?: string;
    navigate?: any;
    dispatch: Dispatch<any>;
    setLoading?: (loading: boolean) => void;
    setErrors?: (errors: any) => void;
    successMessage?: string;
    errorMessage?: string;
    successRedirect?: string;
    config?: any;
}

export const apiRequest = async ({
    method,
    url,
    data,
    token,
    navigate,
    dispatch,
    setLoading,
    setErrors,
    successMessage,
    config,
    errorMessage,
    successRedirect,
}: ApiRequestOptions) => {
    try {
        setLoading?.(true);

        const headers = token ? { Authorization: `Bearer ${token}`, config } : {};

        const response = await axios({
            method,
            url,
            data,
            headers,
        });
        if (response.status === 200 && successMessage) {
            dispatch(
                addToast({
                    id: Date.now(),
                    type: 'success',
                    message: successMessage,
                }),
            );
        }

        if (successRedirect && navigate) {
            setTimeout(() => {
                navigate(successRedirect);
            }, 1000);
        }

        return response;
    } catch (error: any) {
        dispatch(
            addToast({
                id: Date.now(),
                type: 'error',
                message: error?.response?.data?.detail || 'Something went wrong. Please try again.',
            }),
        );

        if (setErrors) {
            setErrors({ api: errorMessage || 'Something went wrong. Please try again.' });
        }

        throw error;
    } finally {
        setLoading?.(false);
    }
};
