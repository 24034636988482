import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'; // Change to BrowserRouter if using normal routing
import LoginPage from './pages/auth/Login';
import Sidebar from './layout/Sidebar';
import Dashboard from './pages/dashboard';
import SupplyChain from './pages/SupplyChain';
import RegisterPage from './pages/auth/Register';
import EmailVerificationPage from './pages/auth/Register/emailVerificationSentPage';
import PasswordSetup from './pages/auth/Register/passwordSetup';
import StartPage from './pages/auth/Login/startPage';
import CompanySetup from './pages/auth/Login/companySetup';
import { Chatbot } from './pages/chatbot';
import EmailVerify from './pages/auth/Register/emailVerify';
import OnboardPage from './pages/auth/onboard';
import AmazonComponent from './pages/auth/onboard/amazon';
import AccountActivation from './pages/auth/onboard/accountActivation';
import ForgotPassword from './pages/auth/forgot-password';
import EmptyDashboard from './pages/dashboard/empty-dashboard';
import Connections from './pages/dashboard/connections';
import { NewDashboard } from './pages/new-dashboard';
import ProtectedRoute from './protectedRoute';
import NewDashboardLayout from './new-layout';
import AmazonAdsComponent from './pages/auth/amazon-ads';
import AdsAccountActivation from './pages/auth/amazon-ads/activation';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/email-verification" element={<EmailVerificationPage />} />
        <Route path="/password-setup" element={<PasswordSetup />} />
        <Route path="/get-started" element={<StartPage />} />
        <Route path="/company-setup" element={<CompanySetup />} />
        <Route path="/auth/verify" element={<EmailVerify />} />
        <Route path="/onboard" element={<OnboardPage />} />
        <Route path="/amazon-onboard" element={<AmazonComponent />} />

        <Route path="/verify/amzn_oauth" element={<AccountActivation />} />
        <Route
          path="/verify/ads/amzn_oauth"
          element={<AdsAccountActivation />}
        />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<PasswordSetup />} />
        <Route path="/amazon-ads-onboard" element={<AmazonAdsComponent />} />

        <Route path="/*" element={<Sidebar />}>
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<NewDashboard />} path="new-dashboard" />
          
          <Route element={<SupplyChain />} path="supplychain" />
          <Route element={<>404!</>} path="not-found" />
          <Route path="" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Route>
        {/* navigate only authenticate user */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <NewDashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route element={<EmptyDashboard />} path="home" />
          <Route element={<NewDashboard />} path="new-insights" />
          <Route path="xylic-ai" element={<Chatbot />}>
            <Route path=":chatId" element={<Chatbot />} />
          </Route>
          <Route element={<Connections />} path="connections" />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
