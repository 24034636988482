import styles from './input.module.scss'
import {
    Search,
    SearchIconWrapper,
    StyledInputBase,
} from './styles';
import SearchIcon from '@mui/icons-material/Search';

export const SearchInput = ({ handleSearch, sx }: any) => {
    return (
        <div>
            <Search className={styles.searchInput} sx={{
                ...sx
            }}>
                <StyledInputBase
                    placeholder='Search'
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearch}
                />
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
            </Search>
        </div>
    )
}