import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as XylicLogo } from '../../../assets/svg/xylicLogo.svg';
import { ReactComponent as BottomLogo } from '../../../assets/svg/loginLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../../components/atoms/FormInputField';
import { CustomButton } from '../../../components/atoms/button/button';
import styles from './register.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToast } from '../../../store/toastSlice';
import Loader from '../../../components/atoms/loader';
import TermServices from '../../../components/molecules/terms-services';
import { apiRequest } from '../../../services/onboard';
import PasswordValidation from '../../../utils/passwordValidation';
import { validatePassword } from '../../../services/insights';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handleNavigate } from '../../../utils';

const defaultTheme = createTheme();

const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    company: Yup.string().required('Company is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
});


const RegisterPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false)
    const [popup, setPopup] = React.useState(false);
    const [show, setShow] = React.useState(false)
    const handlePopup = () => {
        setPopup(true);
    };
    const closePopup = () => {
        setPopup(false);
    };

    React.useEffect(() => {
        handleNavigate(navigate, "/register")
    }, [])

    const handleSubmit = async (values: any, { setSubmitting, setErrors }: any) => {
        if (checked) {
            if (!validatePassword(values.password)) {
                setErrors({ password: 'Password does not meet the required criteria.' });
                setSubmitting(false);
                return;
            }
            try {
                const data = {
                    "name": values?.firstName + " " + values?.lastName,
                    "email": values?.email,
                    "password": values?.password,
                    "org_name": values?.company,
                    "phone_no": ""
                };

                const response = await apiRequest({
                    method: 'POST',
                    url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
                    data,
                    dispatch,
                    setLoading: setSubmitting,
                    setErrors,
                    // successMessage: 'Signup successful! Redirecting...',
                    errorMessage: 'Signup failed. Please try again.',
                    // successRedirect: '/email-verification',
                    navigate,
                });
                navigate(`/email-verification?id=${response?.data?.req_id}`)
                localStorage.setItem(response?.data?.req_id, values.email);
            } catch (error) {

            }
        } else {
            dispatch(
                addToast({
                    id: Date.now(),
                    type: 'error',
                    message: 'Please accept the Terms And Conditions',
                }),
            );
        }
        setSubmitting(false);
    };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component='main'
                sx={{ height: '100vh', overflow: 'auto' }}
                className={`font-poppins ${styles.mainCont}`}
            >
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#5B47F5',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            overflow: 'auto'
                        }}
                    >
                        <Grid
                            sx={{
                                gap: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            className='max-sm:hidden'
                        >
                            <p style={{ color: 'white', fontWeight: 800, fontSize: '40px' }}>Xylic</p>
                            <p style={{ color: 'white', fontWeight: 400, fontSize: '20px' }}>
                                {/* Build, Manage and Optimise <br /> */}
                                Automate Insights & measurement<br /> for improved ad efficiency and revenue growth
                            </p>
                            <Button
                                sx={{
                                    padding: '10px',
                                    background: 'white',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    width: '135px',
                                    height: '37px',
                                    textAlign: 'center',
                                    '&:hover': {
                                        background: 'white',
                                    }
                                }}
                                onClick={() => { window.open('https://www.xylic.ai/') }}
                            >
                                Read More
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            position: 'fixed',
                            top: 0,
                            right: '33.3%',
                            filter: 'blur(200px)',
                            background: 'rgb(207, 200, 255)',
                            height: '40%',
                            width: '30%'
                        }}
                        className={styles.logoInnerStyles}
                    />
                    <Grid
                        sx={{
                            position: 'fixed',
                            bottom: 10,
                            filter: 'blur(200px)',
                            background: 'rgb(207, 200, 255)',
                            height: '30%',
                            width: '20%'
                        }}
                    />
                    <Grid
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                        }}
                        className={`${styles.logoInnerStyles} max-sm:h-14 max-sm:w-14 max-sm:hidden`}
                    >
                        <BottomLogo />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    className={`${styles.registerContainer} max-sm:p-8`}
                >
                    <Grid>
                    </Grid>
                    <Typography component='h1' variant='h5' className={styles.headerText}>
                        Create an account
                    </Typography>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            company: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, values }: any) => (
                            <Form>
                                <Box
                                    sx={{ mt: 1 }}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    className={styles.gapStyling}
                                >
                                    <div>
                                        <Field
                                            as={FormInput}
                                            name='firstName'
                                            variant='filled'
                                            label='First Name'
                                            size='small'
                                            sx={{}}
                                        />
                                        <ErrorMessage name='firstName' component='div' className={styles.error} />
                                    </div>
                                    <div>
                                        <Field
                                            as={FormInput}
                                            name='lastName'
                                            variant='filled'
                                            size='small'
                                            label='Last Name'
                                            sx={{}}
                                        />
                                        <ErrorMessage name='lastName' component='div' className={styles.error} />

                                    </div>
                                    <div>
                                        <Field
                                            as={FormInput}
                                            name='email'
                                            variant='filled'
                                            size='small'
                                            label='Business Email'
                                            sx={{}}
                                        />
                                        <ErrorMessage name='email' component='div' className={styles.error} />
                                    </div>
                                    <div>
                                        <Field
                                            as={FormInput}
                                            name='company'
                                            variant='filled'
                                            size='small'
                                            label='Company'
                                            sx={{}}
                                        />
                                        <ErrorMessage name='company' component='div' className={styles.error} />

                                    </div>
                                    <div>
                                        <Field
                                            as={FormInput}
                                            name='password'
                                            variant='filled'
                                            size='small'
                                            label='Password'
                                            type={show ? "text" : "password"}
                                            endIcon={show ? <VisibilityOff onClick={() => setShow(!show)} style={{ cursor: 'pointer' }} /> : <Visibility onClick={() => setShow(!show)} style={{ cursor: 'pointer' }} />}
                                            sx={{}}
                                        />
                                        {(values.password) && <PasswordValidation password={values.password} styles={{ margin: 'none' }} />}
                                        {/* <ErrorMessage name='password' component='div' className={styles.error} /> */}
                                    </div>
                                    <CustomButton type='submit' disabled={isSubmitting || !validatePassword(values.password)}>
                                        {isSubmitting ? <Loader size={25} /> : "Sign Up"}
                                    </CustomButton>

                                    {/* {errors?.api && <div className={styles.error}>{errors.api}</div>} */}

                                    <Grid container display={'flex'} justifyContent={'center'}>
                                        <Grid item>
                                            <Typography fontWeight={500} className={styles.fontStyles}>
                                                Already have an account?
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color={'rgb(95, 70, 254)'}
                                                className={styles.fontStyles}
                                                sx={{ marginLeft: '4px', cursor: 'pointer' }}
                                                onClick={() => navigate('/login')}
                                            >
                                                Login here
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Checkbox {...{ checked: checked, onChange: () => setChecked(!checked) }} />
                                        <Grid item>
                                            <Typography fontWeight={500} className={styles.fontStyles}>
                                                I accept the
                                            </Typography>
                                        </Grid>
                                        <Grid item onClick={() => handlePopup()}>
                                            <Typography
                                                color={'rgb(95, 70, 254)'}
                                                className={styles.fontStyles}
                                                sx={{ marginLeft: '4px', textDecoration: 'underline', cursor: 'pointer' }}

                                            >
                                                Terms And Conditions
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                    {popup && <TermServices closePopup={closePopup} />}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default RegisterPage;
