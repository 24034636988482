import { FilterTabs } from '../charts/dailyCharts/tabPanel';
import { useState } from 'react';
import styles from './styles.module.scss'; // Import SCSS module
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import rightArrow from '../../../../assets/svg/sidebar/rightArrow.svg';
import leftArrow from '../../../../assets/svg/sidebar/leftArrow.svg';
import { setShowSidebar } from '../../../../store/header/reducer';
import logo from '../../../../assets/svg/xylicIcon.svg';
import { useMediaQuery } from '@mui/system';
export const Header = () => {
  const location = useLocation();

  const { showSide } = useSelector((state: any) => state?.headerContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getIcon = () => {
    return showSide ? rightArrow : leftArrow;
  };
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <div
      className={
        styles.headerContainer + `${isSmallScreen ? 'w-full mx-[10px]' : ''}`
      }
    >
      {/* {location.pathname.includes('/new-insights') ? ( */}
      <>
        <div className={styles.logoContainer}>
          <div
            className={styles.logoChildContainer}
            onClick={() => navigate('/home')}
          >
            <img src={logo} alt="logo" width={20} height={20} />
            <span className={styles.logo_name}>Xylic</span>
          </div>
          {isSmallScreen && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0px',
                cursor: 'pointer',
              }}
              onClick={() => dispatch(setShowSidebar(!showSide))}
            >
              <img src={getIcon()} alt="icon" />
            </div>
          )}
        </div>
        {location.pathname.includes('/new-insights') && (
          <>
            <div className={styles.container}>
              <FilterTabs />
            </div>
          </>
        )}
      </>
    </div>
  );
};
