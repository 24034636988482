import React from 'react';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { StyledTabpanel } from './styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export interface Tab {
  name: string;
  id: number;
  active?: boolean;
  icon?: any
}
interface ITabpanelProps {
  children?: any;
  labelKey?: string;
  itemKey?: string;
  activeTab?: Array<any>;
  setActiveTab?: (item: Tab) => void;
  onClick?: () => void;
  handleTab?: (item: Tab) => void;
  sx?: any;
  textColor: 'primary';
  variant: 'scrollable' | 'standard';
  tabs?: Array<Tab>;
  defaultVal?: any;
  selectedTab?: string;
  xsm?: any;
}

const TabPanel = forwardRef(
  (
    {
      textColor = 'primary',
      variant = 'standard',
      children,
      setActiveTab = () => {
        /* */
      },
      handleTab = () => { },
      onClick = () => { },
      sx,
      tabs = [],
      defaultVal = tabs[0].name,
      selectedTab,
      xsm = false,
      ...other
    }: ITabpanelProps,
    ref
  ) => {
    const theme = useTheme();
    const { permissions, loading } = useSelector((state: any) => state?.headerContext);
    const location = useLocation(); // Get current path

    const filteredTabs =['/home' , '/connections'].includes(location.pathname)
      ? tabs.map((tab) => {
        const tabNameLower = tab.name.toLowerCase();

        // Handle specific cases where the permission key doesn't directly match the tab name
        const permissionKey = tabNameLower === 'connections' ? 'connectors' : tabNameLower;

        return {
          ...tab,
          disabled: !permissions[permissionKey]
        };
      })
      : tabs;

    const [alignment, setAlignment] = React.useState(defaultVal);

    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      alignment: string
    ) => {
      if (alignment != null) {
        setAlignment(alignment);
      }
    };

    React.useEffect(() => {
      setAlignment(selectedTab);
    }, [selectedTab]);

    return (
      <StyledTabpanel
        ownerState={{ textColor, variant }}
        sx={{
          ...sx,
        }}
        theme={theme}
        {...other}
        defaultChecked
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            height: variant === 'scrollable' ? 224 : 'max-content',
          }}
        >
          <Tabs orientation='vertical' variant={variant}>
            {loading
              ? // Render Skeletons while loading is true
              [1, 2, 3].map((_, index) => (
                <Box sx={{ pr: 4 }} key={index}>
                  <Skeleton variant="rectangular" width={210} height={50} sx={{ borderRadius: 2 }} />
                </Box>
              ))
              : // Render Tabs when loading is false
              filteredTabs?.map((item: any, index) => (
                <Box sx={{ pr: 4, ...item?.style }} key={index}>
                  <ToggleButtonGroup
                    sx={{ pl: 4 }}
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    onClick={() => item?.disabled ? () => { } : handleTab(item)}
                    aria-label='Platform'
                  >
                    <ToggleButton
                      value={item.name}
                      sx={{
                        textTransform: 'none',
                        gap: '12px',
                        opacity: item?.disabled ? 0.5 : 1,
                        pointerEvents: item?.disabled ? 'none' : 'all'
                      }}
                    >
                      {item?.icon && item?.icon}
                      {!item.hide && item.name}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              ))
            }
          </Tabs>
        </Box>
      </StyledTabpanel>
    );
  }
);

export default TabPanel;
