import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setDashboardDaysFilter } from '../../../../../store/header/reducer';
import { TimePeriodEnum } from '../../../../../api/sales-metrics-service/salesMetricsSchemas';

// interface FilterTabsProps {
//   selectedFilter: number;
//   onFilterChange: (newValue: number) => void;
// }

// const tabLabels = ['LIVE', '1D', '1W', '1M', '3M', 'YTD', '1Y', 'ALL'];
// const tabLabels = ['1D', '1W', '1M', '3M', 'YTD', '1Y', 'ALL'];

export const FilterTabs: React.FC = () => {
  const tabMapping: Record<string, TimePeriodEnum> = {
    '1D': '1d',
    '1W': '7d',
    '1M': '1m',
    YTD: 'ytd',
    '1Y': '1y',
    ALL: 'alltime',
  };
  const tabLabels = Object.keys(tabMapping);

  const [selectedFilter, setSelectedFilter] = useState<TimePeriodEnum>('7d');
  const dispatch = useDispatch();
  const handleFilterChange = (_: React.SyntheticEvent, newValue: number) => {
    const selectedLabel = tabLabels[newValue];
    const selectedValue = tabMapping[selectedLabel];
    setSelectedFilter(selectedValue); // Update state with the corresponding enum value
    dispatch(setDashboardDaysFilter(selectedValue));
  };
  const selectedIndex = tabLabels.findIndex(
    label => tabMapping[label] === selectedFilter
  );

  return (
    <Box sx={{ minHeight: '10px', padding: '0 1px', maxWidth: '100%' }}>
      {' '}
      {/* Further reduced padding */}
      <Tabs
        value={selectedIndex}
        onChange={handleFilterChange}
        aria-label="filter tabs"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          sx: { backgroundColor: '#A259FF', height: '2px' },
        }} // Purple indicator
        sx={{
          minHeight: '20px', // Reduced tab container height
        }}
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={index}
            label={label}
            sx={{
              minHeight: '30px',
              minWidth: '30px', // Small width for each tab
              padding: '0px', // No padding around tabs
              margin: '0px 2px', // Compact spacing between tabs
              fontWeight: 'bold',
              fontSize: '10px', // Smaller font size to match the image
              color: '#000', // Black color for non-selected tabs
              '&.Mui-selected': { color: '#A259FF' }, // Purple color for selected tab
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
