import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// Define a custom type for criteria
type Criterion = 
  | { label: string; test: RegExp }
  | { label: string; isMatch: () => boolean };

const PasswordValidation = ({ password, confirmPassword, styles, reset }: any) => {
  const criteria: Criterion[] = [
    { label: 'Contains an uppercase letter', test: /[A-Z]/ },
    { label: 'Contains a lowercase letter', test: /[a-z]/ },
    { label: 'At least 8 characters', test: /.{8,}/ },
  ];

  const greenColor = '#4caf50'; // Updated green color
  const redColor = '#d32f2f'; // Red color

  // Add password match criterion if reset is true
  if (reset) {
    criteria.push({
      label: 'Passwords do not match, try again',
      isMatch: () => password === confirmPassword, // New function for matching
    });
  }

  return (
    <Box sx={{ maxWidth: '400px', margin: '0 auto', marginTop: '5px', ...styles }}>
      {criteria.map((criterion, index) => {
        const isValid = 'test' in criterion
          ? password.match(criterion.test)
          : criterion.isMatch && criterion.isMatch();

        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
              fontSize: '1rem',
            }}
          >
            {isValid ? (
              <CheckCircleIcon sx={{ color: greenColor, marginRight: '0.5rem' }} />
            ) : (
              <CancelIcon sx={{ color: redColor, marginRight: '0.5rem' }} />
            )}
            <Typography
              component="span"
              sx={{
                color: isValid ? greenColor : redColor,
                fontWeight: isValid ? 'bold' : 'normal',
              }}
            >
              {criterion.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default PasswordValidation;
