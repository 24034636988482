import { httpClient } from './httpClient';
export const BASEURL = 'https://api.xylicdata.com'

export const getBestSellers = (TableProps?: any) => {
    try {
        const { based_on, page_size, page, timeline = '15 days', delivery, location, age_group, products, category } = TableProps;
        const response = httpClient.get(
            `${BASEURL}/dashboard/best_seller_new?based_on=${based_on}&limit=${page_size}&page=${page}&timeline=${timeline !== 0 ? timeline : '15 days'}` +
            (products.length > 0 || category.length > 0 ? `&products=${[...category, ...products]}` : '') +
            (age_group.length > 0 ? `&age_group=${age_group}` : '') +
            (location.length > 0 ? `&location=${location}` : '')
        );
        return response;
    } catch (error) {
    }
};

export const getCategoryData = (param?: any, page = 0) => {
    const response = httpClient.get(
        `${BASEURL}/utils/unique_values?column=product_category`
    );
    return response;
}

export const getUtilsData = (param?: any, page = 0) => {
    const response = httpClient.get(
        `${BASEURL}/utils/${param}&offset=${page}`
    );
    return response;
};

export const getCustomerSwitchData = (TableProps: any) => {
    const { timeline = '15 days', brand, delivery, location, age_group, products } = TableProps;
    const response = httpClient.get(
        `${BASEURL}/kpi/customer_segment_on_time?brand=${[brand, ...products]}&timeline=${timeline}` +
        (delivery.length > 0 ? `&delivery=${delivery}` : '') +
        (age_group.length > 0 ? `&age_group=${age_group}` : '') +
        (location.length > 0 ? `&location=${location}` : '')
    );
    return response;
}

export const getLoyalityData = (TableProps: any) => {
    const { timeline = '15 days', brand, based_on, delivery, location, age_group, products } = TableProps;
    const response = httpClient.get(
        `${BASEURL}/dashboard/loyality?based_on=${based_on}&products=${[brand, ...products]}&timeline=${timeline}` +
        (delivery.length > 0 ? `&delivery=${delivery}` : '') +
        (age_group.length > 0 ? `&age_group=${age_group}` : '') +
        (location.length > 0 ? `&location=${location}` : '')
    );
    return response;
}

export const getOverlapData = (TableProps: any) => {
    const { timeline = '15 days', brand, based_on, delivery, location, age_group, products } = TableProps;
    const response = httpClient.get(
        `${BASEURL}/kpi/customer_overlap?timeline=${timeline}` +
        (delivery.length > 0 ? `&delivery=${delivery}` : '') +
        (age_group.length > 0 ? `&age_group=${age_group}` : '') +
        (location.length > 0 ? `&location=${location}` : '') +
        `&brands=${brand[0]}` + `&brands=${brand[1]}`
    );
    return response;
}


export const getCustomerBrandData = (param?: any, page?: number) => {
    const response = httpClient.get(
        `${BASEURL}/utils/items_for_segment_on_time?offset=${page}&limit=10`
    );
    return response;
};

export const getLoyalityBrandData = (param?: any, page?: number) => {
    const response = httpClient.get(
        `${BASEURL}/utils/items_for_loyality?offset=${page}&limit=10`
    );
    return response;
};

export const getOverlapBrandData = (param?: any, page?: number) => {
    const response = httpClient.get(
        `${BASEURL}/utils/items_for_overlap?offset=${page}&limit=10`
    );
    return response;
};

export const getOverlap = (param?: any) => {
    const response = httpClient.get(
        `${BASEURL}/utils/items_for_overlap`,
        param
    );
    return response;
};

export const getOverlapChartData = (params?: any) => {
    const endpoint = `${BASEURL}/kpi/customer_overlap`;
    return parseParams(endpoint, params);
};

export const parseParams = (endpoint: string, params: any) => {
    if (params && Object.keys(params).length > 0) {
        const searchParams = new URLSearchParams();

        Object.entries<any>(params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    searchParams.append(key, item.toString());
                });
            } else {
                searchParams.append(key, value.toString());
            }
        });

        const queryString = searchParams.toString();
        return httpClient.get(`${endpoint}?${queryString}`);
    } else {
        return httpClient.get(endpoint);
    }
}

export const getPromotionsData = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/promoanalysis`;
    return parseParams(endpoint, params);
};

export const getDashboardNumericals = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/numericals`;
    return parseParams(endpoint, params);
};

export const getDashboardTreeData = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/tree_insight`;
    return parseParams(endpoint, params);
};

export const getDashboardLoyalityData = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/loyality`;
    return parseParams(endpoint, params);
}

export const getDashboardCustomerData = (params?: any) => {
    const endpoint = `${BASEURL}/kpi/customer_segment_on_time`;
    return parseParams(endpoint, params);
}

export const getDashboardPromotionsData = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/promoanalysis`;
    return parseParams(endpoint, params);
}

export const getDashboardBestSellers = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/best_seller_new`;
    return parseParams(endpoint, params);
}

export const getDashboardMap = (params?: any) => {
    const endpoint = `${BASEURL}/dashboard/density_based`;
    return parseParams(endpoint, params);
}


export const getAutoStack = (TableProps: any) => {
    const { brand = [], products = [], category } = TableProps;
    const endpoint = `${BASEURL}/segmention/autostack?item=${[...brand, ...products, ...category]}`
    const response = httpClient.get(
        endpoint
    );
    return response;
}

export const getPromoBrands = (params?: any) => {
    const endpoint = `${BASEURL}/utils/items_for_promo_analyis`;
    return parseParams(endpoint, params);
}


export const getSegmentBrands = (params?: any) => {
    const endpoint = `${BASEURL}/utils/items_for_segment_brand`;
    const response = httpClient.get(
        endpoint
    );
    return response;
}

export const getSegmentCategories = (params?: any) => {
    const endpoint = `${BASEURL}/utils/items_for_segment_category`;
    const response = httpClient.get(
        endpoint
    );
    return response;
}


export const getSegmentProcessData = (TableProps: any) => {
    const { path } = TableProps;
    const endpoint = `${BASEURL}/process/${path}`
    const response = httpClient.get(
        endpoint
    );
    return response;
}



export const publishSegmentData = (TableProps: any) => {
    const { item, process, parameter, users } = TableProps;
    const response = httpClient.get(
        `${BASEURL}/segmention/manual_stack?item=${item}&process=${process}&parameter=${parameter}&users=${users}`
    );
    return response;
}


export const validatePassword = (password: string) => {
    const criteria = [
        { label: 'Contains an uppercase letter', test: /[A-Z]/ },
        { label: 'Contains a lowercase letter', test: /[a-z]/ },
        { label: 'At least 8 characters', test: /.{8,}/ },
        // { label: 'Contains a number', test: /\d/ },
    ];

    return criteria.every(criterion => password.match(criterion.test));
};