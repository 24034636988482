import React from "react";
import styles from "./index.module.scss";
import { ReactComponent as ClipIcon } from '../../../../assets/svg/clip.svg';
import { ReactComponent as MoreIcon } from '../../../../assets/svg/morehorizontal.svg';

const CardContainer = ({children, title}: any) => {
  return (
    <div className={styles["card-container"]}>
      <div className={styles["card-header"]}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className={styles["card-title"]}>{title}</p>
          <ClipIcon />
        </div>
        <MoreIcon />
      </div>
      {children}
    </div>
  );
};

export default CardContainer;
