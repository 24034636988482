import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    menuName: ""
};

export const nodeMenuSlice = createSlice({
	name: 'nodeMenu',
	initialState,
	reducers: {
		setNode: (state,action) => {
			state.menuName = action.payload?.name;
		},
	}
});

export const {setNode} = nodeMenuSlice.actions;
export default nodeMenuSlice.reducer;