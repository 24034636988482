import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as BottomLogo } from "../../../../assets/svg/centerLogo.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../../Login/login.module.scss";
import { CircularProgress, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { apiRequest } from "../../../../services/onboard";
import { useDispatch } from "react-redux";

const defaultTheme = createTheme();

export default function AccountActivation() {
  const [loading, setLoading] = React.useState(true);
  const [activated, setActivated] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const conn = searchParams.get("conn");

  React.useEffect(() => {
    const state = searchParams.get("state");
    const selling_partner_id = searchParams.get("selling_partner_id");
    const spapi_oauth_code = searchParams.get("spapi_oauth_code");

    if (state && selling_partner_id && spapi_oauth_code) {
      const state_obj_item = localStorage.getItem(state);

      if (!state_obj_item) {
        console.error("No state object found!");
        return;
      }

      const state_obj = JSON.parse(state_obj_item);
      const marketplace = state_obj.marketplace;
      const next_page = state_obj.next_page;
      const token = localStorage.getItem("access_token");
      const verifyOAuth = async () => {
        try {
          if (!token) {
            throw new Error("No access token found");
          }

          // Verify OAuth code
          await apiRequest({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/amazon/oauth2_verify`,
            data: {
              marketplace_id: marketplace,
              code: spapi_oauth_code,
              seller_id: selling_partner_id,
            },
            token: localStorage.getItem("access_token") || "",
            dispatch,
            errorMessage: "Error while verifying the Amazon login!",
          });

          // Check if onboarding is finished
          await apiRequest({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/auth/onboarding_finished`,
            config: {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
            token: localStorage.getItem("access_token") || "",
            dispatch,
            errorMessage: "Activation failed!",
          });

          // Handle success
          setActivated(true);
          localStorage.setItem("is_onboarded", "true");
          toast.success("Marketplace Activation Successfully!");
          // navigate('/home')
          setTimeout(() => {
            navigate(next_page);
          }, 2000);
        } catch (error:any) {
          console.error("An error occurred:", error);
          toast.error(error?.message || "An unexpected error occurred");
          setLoading(false);
        }
      };

      // Start OAuth verification
      verifyOAuth();
    }
  }, [searchParams, navigate, dispatch]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />

        <Grid
          item
          md={12}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "#5B47F5",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="16px"
              className={styles.mainLoginCont}
              sx={{
                background: "white",
                width: "540px",
                height: "300px",
                padding: "0px 50px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#000", fontFamily: "Poppins" }}
              >
                Account Activation
              </Typography>
              {loading ? (
                <CircularProgress sx={{ color: "#5B47F5" }} />
              ) : activated ? (
                <CheckCircleOutline sx={{ color: "green", fontSize: "48px" }} />
              ) : (
                <Typography variant="body1" sx={{ color: "red" }}>
                  Activation failed. Please try again.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid
            sx={{
              position: "fixed",
              bottom: 0,
              left: "40%",
              zIndex: 1,
            }}
          >
            <BottomLogo />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
