import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import styles from './styles.module.scss';
import axios from 'axios';
import Loader from '../../../../components/atoms/loader';
import { addToast } from '../../../../store/toastSlice';
import { useDispatch } from 'react-redux';
import { makeid } from '../../../../utils';
import { apiRequest } from '../../../../services/onboard';

const defaultTheme = createTheme();

const AmazonComponent = () => {
    const navigate = useNavigate();
    const [marketplace, setMarketplace] = React.useState('');
    const [marketplaces, setMarketplaces] = React.useState<any>([]);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const conn = queryParams.get("conn");

    const handleChange = (event: any) => {
        setMarketplace(event.target.value);
    };

    const dispatch = useDispatch()
    const handleGoToAmazon = async () => {
        if (marketplace) {
            const conn2 = conn === 'false' ? '/home' : '/connections'
            const url = await getTheUrl(marketplace, conn2)
            // const selectedMarketplace = marketplaces.find((m: any) => m?.marketplace_id === marketplace);
            if (url) {
                window.location.href = url?.data?.url;
            }
        } else {
            dispatch(
                addToast({
                    id: Date.now(),
                    type: 'error',
                    message: 'Please Select MarketPlace.',
                })
            );
        }
    };

    const handleCancel = async () => {
        try {
            const token = localStorage.getItem('access_token'); // Get the bearer token from local storage

            if (!token) {
                throw new Error('No access token found');
            }

            // Call the onboarding finished endpoint using the utility function
            await apiRequest({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/auth/onboarding_finished`,
                config: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
                token: localStorage.getItem('access_token') || '',
                dispatch,
                errorMessage: 'Please try again.',
            });

            // Set the onboarding status in localStorage and navigate to the home route
            localStorage.setItem('is_onboarded', "true");
            if (conn === 'false') {
                navigate('/home');
            } else {
                navigate('/connections');
            }

        } catch (error) {
            console.error('Error during cancel operation:', error);
            dispatch(
                addToast({
                    id: Date.now(),
                    type: 'error',
                    message: 'Please try again.',
                })
            );
        }
    };

    const getTheUrl = async (marketplace: any, next_page: any) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('access_token'); // Retrieve token from localStorage

            if (!token) {
                throw new Error('No token found');
            }

            // Create and store the state object
            const state_obj = { next_page, marketplace };
            const state_id = makeid(8);
            localStorage.setItem(state_id, JSON.stringify(state_obj));

            // Make the API request using the utility function
            const response = await apiRequest({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/amazon/oauth2?marketplace_id=${marketplace}&state_id=${state_id}`,
                config: {
                    headers: {
                        Authorization: `Bearer ${token}`, // Pass token as Bearer token
                    },
                },
                token: localStorage.getItem('access_token') || '',
                dispatch,
                errorMessage: 'Failed to retrieve URL. Please try again.',
            });

            // Return the response to the caller
            return response;
        } catch (err: any) {
            setError(err?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };


    React.useEffect(() => {
        const fetchMarketplaces = async () => {
            try {
                const token = localStorage.getItem('access_token');  // Retrieve token from localStorage
                if (!token) {
                    throw new Error('No token found');
                }

                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/amazon/marketplaces`, {
                    headers: {
                        'Authorization': `Bearer ${token}`  // Pass token as Bearer token
                    }
                });

                setMarketplaces(response.data);  // Assuming response data is an array
            } catch (err: any) {
                setError(err?.message);
            } finally {
                // setLoading(false);
            }
        };

        fetchMarketplaces();
    }, []);

    // if (loading) {
    //     return <CircularProgress />;
    // }

    // if (error) {
    //     return <div>Error: {error}</div>;
    // }

    const customMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',  // Set the maximum height for the dropdown
                overflow: 'auto',    // Enable scrolling when content overflows
            },
        },
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid sx={{ height: '100vh', overflow: 'auto' }}            >
                <Grid
                    container
                    component="main"
                    sx={{ overflow: 'auto' }}
                    className={`font-poppins ${styles.mainCont}`}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={5}
                        sx={{
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#5B47F5',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            padding: '0 40px',
                        }}
                        className='max-sm:hidden'
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100vh',
                            }}

                        >
                            <Grid
                                sx={{
                                    gap: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        color: 'white',
                                        fontWeight: 800,
                                        fontSize: '36px',
                                        lineHeight: '44px',
                                        letterSpacing: '0.5px',
                                    }}
                                >
                                    Welcome to Xylic
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'white',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                        lineHeight: '28px',
                                    }}
                                >
                                    Streamline your business processes and boost productivity.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                position: 'fixed',
                                top: 0,
                                right: '33.3%',
                                filter: 'blur(200px)',
                                // background: 'rgb(207, 200, 255)',
                                height: '40%',
                                width: '30%',
                            }}
                        />
                        <Grid
                            sx={{
                                position: 'fixed',
                                bottom: 10,
                                filter: 'blur(200px)',
                                // background: 'rgb(207, 200, 255)',
                                height: '30%',
                                width: '20%',
                            }}
                        />
                        <Grid
                            sx={{
                                position: 'fixed',
                                bottom: -30,
                                left: -30
                            }}
                        >
                            {/* Existing OnboardLogo */}
                        </Grid>
                    </Grid>
                    <Grid item
                        xs={12}
                        sm={8}
                        md={7}
                        sx={{

                            gap: '12px',
                            padding: '40px',
                            paddingLeft: '100px',
                            position: 'relative',
                        }}
                        className='max-sm:px-8 max-sm:w-full'>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'start',
                                height: '100%'
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 500,
                                    marginBottom: '0px',
                                }}

                            >
                                Add an Amazon Seller Account
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    marginBottom: '16px',
                                }}

                            >
                                Taking you to Amazon Seller Central
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginBottom: '32px',
                                    textAlign: 'start',
                                    color: '#555555',
                                    width: '84%'
                                }}
                                className='max-sm:w-full'
                            >
                                You will be taken to an Amazon Seller Central page to authorize Xylic access to your Seller Partner account.
                            </Typography>
                            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
                                <Grid item>
                                    <Select
                                        value={marketplace}
                                        onChange={handleChange}
                                        displayEmpty
                                        sx={{ width: '300px', height: '40px', fontFamily: "poppins" }}
                                        MenuProps={customMenuProps}
                                    >
                                        <MenuItem value="" sx={{ fontFamily: "poppins" }}>
                                            <em>Select marketplace</em>
                                        </MenuItem>
                                        {marketplaces.map((market: any) => (
                                            <MenuItem key={market?.marketplace_id} value={market?.marketplace_id} sx={{ fontFamily: "poppins" }}>
                                                {/* <img
                                            src={`https://www.countryflags.io/${market.country_code}/flat/24.png`}  // URL pattern for flag icons
                                            alt={market.country}
                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                        /> */}
                                                {market?.flag} {market?.url.replace(/https:\/\/sellercentral(-europe)?/, 'www')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <a
                                        href="#"
                                        onClick={handleGoToAmazon}
                                        style={{
                                            display: 'inline-block',
                                            textDecoration: 'none',
                                            color: '#1976d2',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {loading ? <Loader /> : "Go to Amazon"}

                                    </a>
                                </Grid>
                            </Grid>
                            <Button
                                variant="text"
                                onClick={handleCancel}
                                sx={{
                                    color: '#555555',
                                    position: {xs:'fixed',sm:'absolute'},  // Position button absolutely
                                    bottom: '40px',         // Adjust distance from bottom as needed
                                    left: '40px',
                                    height: '40px'          // Adjust distance from left as needed
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default AmazonComponent;
