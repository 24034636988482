import React, { useState } from "react";
import SearchHeader from "./seach-header";

export default function SupplyChain() {
  const [view, setView] = useState(0);

  return (
    <div style={{ padding: "16px", paddingTop: "0px" }}>
      <SearchHeader
        onViewChange={(e: any) => {
          if (e.target.value == "forcasted_trend") {
            setView(0);
          } else {
            setView(1);
          }
        }}
      />
      <div style={{"overflow": "scroll", height: "90vh"}}>
        <img
          className="w-full"
          src={view == 0 ? `/forcasted_trend.png` : "impact_products.png"}
        />
      </div>
    </div>
  );
}
