import styles from './styles.module.scss'; // Importing SCSS module
import CardWrapper from './components/card-wrapper';
import { SalesChart } from './components/charts/dailyCharts/sales-chart';
import { KpiSection } from './components/charts/kpi-section';
import { useSelector } from 'react-redux';

export const NewDashboard = () => {
    const { tab } = useSelector((state: any) => state?.headerContext);
    return (
        <div style={{ display: 'flex', gap: '12px' }}>
            {tab === 0 && 
            <div className={styles.dashboardContainer}>
                <CardWrapper className={'salesChartContainer'}>
                    <SalesChart />
                </CardWrapper>
        
                
                {/* <div style={{ display: 'flex', gap: '12px' }}>
                    <KpiSection />
                </div> */}
                 <div className={styles.kpiSectionWrapper}>
                    <KpiSection />
                </div>
        </div>}
        
            
        </div>
    );
};
