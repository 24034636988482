import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	brand: [],
	category:[],
	delivery:[],
	location:[],
	age_group:[],
	products:[]
};

export const appContextSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		onBrandChange: (state,action) => {
			state.brand = action.payload?.brand;
		},
		onCategoryChange: (state,action) => {
			state.category = action.payload?.category;
		},
		onDeliveryChange: (state,action) => {
			state.delivery = action.payload?.delivery;
		},
		onLocationChange: (state,action) => {
			state.location = action.payload?.location;
		},
		onAgeGroupChange: (state,action) => {
			state.age_group = action.payload?.age_group;
		},
		onProductChange: (state,action) => {
			state.products = action.payload?.products;
		},
	}
});

export const {onDeliveryChange,onAgeGroupChange,onLocationChange,onProductChange,onCategoryChange,onBrandChange} = appContextSlice.actions;
export default appContextSlice.reducer;