import axios, { Axios, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class HttpClient {
  private axiosInstance: Axios;
  constructor(apiVersion: string | undefined) {
    this.axiosInstance = new axios.Axios({
      baseURL: process.env.REACT_APP_BASE_URL + '/',
    });

    this.axiosInstance.interceptors.request.use(
      (request: any) => {
        request.headers = {
          ...request.headers,
          'content-type': 'application/json',
        } as any;

        return request;
      },
      undefined,
      { synchronous: true }
    );

    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        if (response.status >= 400) {
          // store.dispatch(showNotification({
          //   showNotification: true,
          //   message: response.statusText,
          //   type: 'error'
          // }));
          const error = JSON.parse(response?.data);
          toast.error(error?.Message ?? '400 Error');

          if (response.status === 401) {
            toast.error('Bad Gateway 401');
          } else if (response.status === 595) {
            toast.warning('595 error');
          } else if (response.status === 596) {
          }
          return response;
        } else {
          try {
            return JSON.parse(response.data);
          } catch (error) {
            return response.data;
          }
        }
      },
      () => {
        // store.dispatch(setLoader(false));
        // store.dispatch(showNotification({
        //   showNotification: true,
        //   message: err.message,
        //   type: 'error'
        // }));
      },
      { synchronous: true }
    );
  }

  get<T>(endpoint: string, params?: Record<string, any>): Promise<T> {
    return this.axiosInstance.get<any, T>(endpoint, {
      params
    });
  }

  delete<T>(endpoint: string, params?: Record<string, any>): Promise<T> {
    return this.axiosInstance.delete<any, T>(endpoint, {
      ...params,
    });
  }

  uploadFile<T>(
    endpoint: string,
    body: any,
    {
      params,
      headers = {},
    }: { params?: Record<string, any>; headers: Record<string, any> }
  ) {
    return this.axiosInstance.post<T>(endpoint, body, {
      params,
      headers,
    });
  }

  post<T>(
    endpoint: string,
    body?: any,
    params?: Record<string, any>
  ): Promise<AxiosResponse<T, any>> {
    return this.axiosInstance.post<T>(endpoint, JSON.stringify(body), {
      params,
    });
  }

  put<T>(
    endpoint: string,
    body?: any,
    params?: Record<string, any>
  ): Promise<AxiosResponse<T, any>> {
    return this.axiosInstance.put<T>(endpoint, JSON.stringify(body), {
      params,
    });
  }
}

export const httpClient = new HttpClient(process.env.REACT_APP_BASE_URL);
