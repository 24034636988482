import React from 'react';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

const CardWrapper = ({
  title,
  showHeader = false,
  children,
  onClick,
  showButton,
  sx = {},
  className = ''
}: any) => {
  return (
    <div className={`${styles.mainCard} ${styles?.[className]}`} style={{ ...sx }}>
      {showHeader && (
        <div className={styles.cardHeader}>
          <p
            className={styles.title}
            onClick={onClick}
          >
            <span>{title}</span>
          </p>
          {showButton && <div className={styles.rightButton}>
            See all
          </div>}
        </div>
      )}
      <Box sx={{ paddingTop: '0px', height: '100%' }}>
        {children}
      </Box>
    </div>
  );
};

export default CardWrapper;
