// store/filtersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FiltersState {
  selectedFilters: Record<string, string[]>;
  filterOptionsCache: Record<string, string[]>;
  filterDropdownStates: Record<string, boolean>;
}

const initialState: FiltersState = {
  selectedFilters: {},
  filterOptionsCache: {},
  filterDropdownStates: {},
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    toggleFilterDropdown: (state, action: PayloadAction<string>) => {
      const filterName = action.payload;
      state.filterDropdownStates[filterName] = !state.filterDropdownStates[
        filterName
      ];
    },
    updateFilterOptionsCache: (
      state,
      action: PayloadAction<{ filter: string; options: string[] }>
    ) => {
      const { filter, options } = action.payload;
      state.filterOptionsCache[filter] = options;
    },
    toggleFilterOption: (
      state,
      action: PayloadAction<{ label: string; option: string }>
    ) => {
      const { label, option } = action.payload;
      const currentOptions = state.selectedFilters[label] || [];
      if (currentOptions.includes(option)) {
        state.selectedFilters[label] = currentOptions.filter(
          item => item !== option
        );
      } else {
        state.selectedFilters[label] = [...currentOptions, option];
      }
    },
  },
});

export const {
  toggleFilterDropdown,
  updateFilterOptionsCache,
  toggleFilterOption,
} = filtersSlice.actions;

export default filtersSlice.reducer;
