import React, { forwardRef, useState, useEffect } from 'react';
import { StyledSelect } from './styles';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import { SelectChangeEvent, FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import { maxHeight, minWidth } from '@mui/system';

type Option = {
  id?: string | number;
  label?: string | any;
  value?: string | number;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};
interface FormInputProps {
  type?: React.HTMLInputTypeAttribute;
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'filled' | 'standard';
  name?: string;
  disabled?: boolean;
  multiple?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  innerRef?: React.RefObject<HTMLInputElement>;
  startIcon?: React.ReactSVGElement | React.ReactNode;
  endIcon?: React.ReactSVGElement | React.ReactNode;
  options?: Array<Option>;
  optionsType?: string;
  sx?: any;
  itemHeight?: any;
  itemPaddingTop?: any;
  itemWidth?: any;
  itemStyles?: React.CSSProperties;
  onChange?: any;
  value?: any;
  placeholder?: string;
  disableUnderline?: boolean;
  onOpen?: any;
  onClick?: any;
  Icon?: any;
  Listselect?: boolean;
  Menustyle?: any;
  className?: string;
  IconComponent?: any;
}

const Select = forwardRef((props: FormInputProps, ref) => {
  const theme = useTheme();
  const [selected, setSelected] = React.useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === 'string' ? value.split(',') : value);
    props.onChange(event);
  };
  const {
    size = 'small',
    variant = 'outlined',
    name,
    disabled,
    onBlur,
    onFocus,
    onClick,
    options = [],
    multiple = false,
    optionsType = 'default',
    sx,
    itemHeight = 48,
    itemPaddingTop = 8,
    itemWidth = 250,
    itemStyles,
    value,
    placeholder = '',
    disableUnderline,
    onOpen,
    Icon,
    Listselect,
    Menustyle,
    className,
    IconComponent,
  } = props;
  const handleSelect = (event: SelectChangeEvent<any>) => {
    setSelectedOption(event.target.value);
    props.onChange(event);
  };
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight:'300px',
        // minWidth:'100px',
        boxShadow: 0,
        fontFamily: 'Poppins',
        marginTop: '1px',
        border: '1px solid #EAECF0',
        borderBottom: 'none'
      },    },
  };
  useEffect(() => {
    if (options.length > 0) {
      const data = options.filter((item) => item.value === value);
      setSelected(
        typeof data[0]?.value === 'string'
          ? data[0]?.value.split(',')
          : data[0]?.value
      );
      setSelectedOption(data[0]?.value);
    }
  }, [value]);

  const CustomSelectIcon = ({ open, handleMenuClose, handleMenuOpen }: any) => {
    const toggleDropdown = () => {
      if (open) {
        handleMenuClose();
      } else {
        handleMenuOpen();
      }
    };
    return (
      <div
        onMouseDown={toggleDropdown}
        style={{
          cursor: 'pointer',
        }}
      >
        {Icon}
      </div>
    );
  };

  return (
    <FormControl sx={{ minWidth: '100%' }}>
      {Listselect ? (
        <StyledSelect
          className={className}
          open={isOpen}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          value={selectedOption}
          onChange={handleSelect}
          ownerState={{ size }}
          theme={theme}
          disabled={disabled}
          // IconComponent={() => (Icon)}
          IconComponent={(props) => (
            <CustomSelectIcon
              {...props}
              open={isOpen}
              handleMenuClose={handleMenuClose}
              handleMenuOpen={handleMenuOpen}
            />
          )}
          sx={sx}
          onBlur={onBlur}
          name={name}
          renderValue={() => {
            const selectedOptionLabel = options.find(
              (option) => option.value === selectedOption
            )?.label;
            return selectedOptionLabel || '';
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
                border: '1px solid rgba(191, 191, 191, 0.25)',
                borderRadius: '9px',
                width:'250px',
                '& .MuiMenuItem-root': {
                  padding: 1,
                  paddingLeft: '32px',
                },
                ...Menustyle,
              },
            },
          }}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  display: 'flex',
                  padding: '10px !important',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '6px',
                  background: 'var(--Base-White, #FFF)',
                  boxShadow: 'none !important',
                  minWidth: '0px !important',
                  borderBottom: '1px solid #D0D5DD'
                }}
              >
                {option.label}
                {isOpen && selectedOption === option.value && (
                  <CheckIcon style={{ marginLeft: 'auto', fill: '#5D5FEF' }} />
                )}
              </MenuItem>
            );
          })}
        </StyledSelect>
      ) : (
        <StyledSelect
          ownerState={{ size }}
          theme={theme}
          id='demo-simple-select-standard'
          labelId='demo-simple-select-label'
          placeholder={placeholder}
          size={size}
          variant={variant}
          onOpen={onOpen}
          IconComponent={IconComponent}
          value={selected}
          onClick={onClick}
          disableUnderline={disableUnderline}
          onChange={handleChange}
          renderValue={
            optionsType == 'chips' && multiple
              ? (selected: any) => {
                const optionLabel = options.filter(
                  (item) => selected === item.value
                );
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: any) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }
              : () => {
                const optionLabel = options.filter(
                  (item) =>
                    (typeof item.value === 'string'
                      ? selected[0]
                      : selected) === item.value
                );
                return (
                  <>
                    {optionLabel[0] ? optionLabel[0].label : optionLabel[0]}{' '}
                  </>
                );
              }
          }
          sx={{
            cursor: 'pointer',
            paddingRight: '20px',
            ':before': { borderBottom: '1px solid #ced4da' },
            ':after': { borderBottom: '1px solid #ced4da' },
            ...sx,
          }}
          MenuProps={{...MenuProps,...Menustyle,}}
          {...{
            name,
            disabled,
            multiple,
            onBlur,
            onFocus,
          }}
        >
          {optionsType == 'checkbox'
            ? options?.map((option: any, index: any) => (
              <MenuItem
                key={index}
                value={option.value}
                sx={{ ...itemStyles }}
              >
                <Checkbox checked={selected.indexOf(option.label) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))
            : options?.map((option: any, index: any) => (
              <MenuItem
                key={index}
                value={option.value}
                sx={{
                  display: 'flex',
                  padding: '6px 8px !important',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '6px',
                  background: 'var(--Base-White, #FFF)',
                  boxShadow: 'none !important',
                  borderBottom: '1px solid #D0D5DD',
                  width:'100%',
                  ...itemStyles
                }}
              >
                {option.label}
              </MenuItem>
            ))}
        </StyledSelect>
      )}
    </FormControl>
  );
});
export default Select;
