/* eslint-disable @typescript-eslint/no-explicit-any */
// ToastContainer.js
"use client"
import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './toast';

const ToastContainer = () => {
  const toasts = useSelector((state: any) => state.toastContext?.toasts);
  // const subTab = useSelector((state: any) => state?.headerContext?.subHeader);
  return (
    <div className="fixed top-0 p-4" style={{ transform: 'translateX(-50%)', zIndex: 100,right:'-150px' }}>
      {toasts?.map((toast: any) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
